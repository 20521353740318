import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createContext , useState } from 'react';
const root = ReactDOM.createRoot(document.getElementById('root'));


export const Context = createContext();


const AppWrapper = () => {
    const [tabRefresh , setTabRefresh] = useState(false)
    const [preTagRefresh , setPreTagRefresh] = useState(false)
    const [refreshVideoPage, setRefreshVideoPage] = useState(false);
    const [refreshStudyPage, setRefreshStudyPage] = useState(false);
    const [freeCoursesRefresh , setFreeCoursesRefresh ] = useState(false)
    const [freeQuestionsRefresh , setFreeQuestionRefresh ] = useState(false)
    const [videoData,setVideoData] = useState([]);
    const [studyMaterial,setStudyMaterial] = useState([]);
    return (
      <Context.Provider
        value={{
            // put states here
            tabRefresh ,setTabRefresh,
            freeCoursesRefresh , setFreeCoursesRefresh 
            ,preTagRefresh , setPreTagRefresh ,
            freeQuestionsRefresh , setFreeQuestionRefresh, 
            videoData,setVideoData,studyMaterial,setStudyMaterial,
            refreshVideoPage,setRefreshVideoPage,
            refreshStudyPage, setRefreshStudyPage
        }}
      >
        <App />
      </Context.Provider>
    );
  };


root.render( 
    <AppWrapper />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
