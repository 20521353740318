import React, { useContext, useEffect } from "react";
import { IMAGE_URL, PREREC_BASE_URL, base_url } from "../env";
import { Context } from "../..";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { TfiControlForward } from "react-icons/tfi";

const StudyMaterial = ({ currentCourseId }) => {
  const Navigate = useNavigate();

  const { studyMaterial, setStudyMaterial } = useContext(Context);
  const {refreshStudyPage, setRefreshStudyPage} = useContext(Context);

  const studyMaterialList = async () => {
    try {
      const url = `${PREREC_BASE_URL}/client/studymaterials/get/courses/study/materials`;
      const token = localStorage.getItem("brainbucks_token");
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({
          course_id: currentCourseId,
        }),
        redirect: "follow",
      });
  
      const result = await response.json();
  
      if (result.status === 1) {
        setStudyMaterial(result.study_materials);
        setRefreshStudyPage(false);
      } else if (result.status === "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
      }
    } catch (error) {
      console.error("Error in studyMaterialList:", error);
    }
  };
  


  useEffect(()=>{
    studyMaterialList();
  },[refreshStudyPage])


  const renderStudyMaterials = () => {
    return studyMaterial.map((data) => (
      <div className="card d-flex flex-row px-3 py-2 mt-2 mb-3 justify-content-between align-items-center" key={data._id}>
        <p className="mb-0">{data.title}</p>
        <TfiControlForward
          style={{ cursor: "pointer" }}
          onClick={() => {
            const pdfUrl = `${IMAGE_URL}${data.pdf_url}`;
  
            if (pdfUrl) {
              window.open(pdfUrl, "_blank");
            }
          }}
        />
      </div>
    ));
  };


  return (
    <>
      {studyMaterial.length > 0 ? renderStudyMaterials() : <div className="d-flex align-items-center justify-content-center h-100"><h3 className="text-center">No Study Material Found</h3></div>}
    <Toaster position="top-right" />
    </>
  );
};

export default StudyMaterial;