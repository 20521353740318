import React, { useState } from 'react'
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate , useParams} from "react-router-dom";
import { base_url } from "../env.js";
import toast, { Toaster } from "react-hot-toast";

const AddQuestion = () => {
  let {_id} = useParams();

    const Navigate = useNavigate();
    const [checked , setChecked] = useState(0)

    const [question , setQuestion] = useState("")
    const [optA , setOptA] = useState("")
    const [optB , setOptB] = useState("")
    const [optC , setOptC] = useState("")
    const [optD , setOptD] = useState("")


    const addQuestionsData = async() => {

      if (checked === 0) {
        toast.error("Answer must be ticked")
      }else{

      try {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${localStorage.getItem("brainbucks_token")}`
        );
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body  : JSON.stringify({
            "question": question,
            "option1":optA,
            "option2":optB,
            "option3":optC,
            "option4":optD,
            "prerec_tagid":_id,
            "ans":checked
          })
        };
    
      const response = await fetch(`${base_url}/quiz/prerec_question/create/pre/rec/question`, requestOptions)
      const result = await response.json();
            if (result.status == "1") {
              toast.success("Question Added Successfully")

              setTimeout(()=>{
                Navigate(-1)
              } , 1000)
            }
    
            else if (result.status === "VAL_ERR") {
              toast.error(result.Backend_Error);
            } else if (result.status == "FILE_VAL_ERR") {
              toast.error(result.Backend_Error);
            } else if (result.status == "TOKEN_ERR") {
              localStorage.removeItem("brainbucks_token");
              localStorage.removeItem("username");
              Navigate("/");
              toast("Logged in other device! Please login again");
            }
      } catch (e) {
        console.log(e);
      }

    }
    };

  return (
    <>
     
    <Toaster/>
            <div className = "m-3 btn btn-dark px-4 py-0" >
              {
                <FaLongArrowAltLeft
                  
                  fontSize={"30px"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    Navigate(-1);
                  }}
                />
              }
            </div>
          

<div style={{height:"10vh"}}></div>
<h1 style={{textAlign:"center"}}>Add New Question</h1>



{/* form */}
<div className='border shadow rounded' style={{width:"80vw" , margin:"auto" ,padding:"1rem" , backgroundColor:"#FFFFFF" } 
     
} >

{/* que */}
  <div className="form-group my-4 " >
    <input type="text" style={{border:"2px solid gray", backgroundColor:"#FFFDD0"}} className="form-control"
   placeholder="Enter your question here"
   value={question}
   onChange={(e)=>{setQuestion(e.target.value)}}
   />
  </div>


{/* options */}
    <div className='d-flex flex-column gap-2'>


<div className='d-flex flex-row gap-2'>
<div>
    <input  className="form-check-input"  style={{  cursor:"pointer" , width:"20px" , height:"20px" ,marginTop:"7px"}} type="radio"  name="inlineRadioOptions" id="inlineRadio1" 
    onClick={()=>setChecked(1)}
/></div>

    <div className="form-group"  style={{cursor:"pointer" , width:"80%"}} >
    <input type="text" className="form-control" placeholder="Enter your option A"
       value={optA}
       onChange={(e)=>{setOptA(e.target.value)}}
    />
    </div>

</div>


<div className='d-flex flex-row gap-2'>
<div>
    <input  className="form-check-input"  style={{  cursor:"pointer" , width:"20px" , height:"20px" ,marginTop:"7px"}} type="radio"  name="inlineRadioOptions" id="inlineRadio2"
    onClick={()=>setChecked(2)}
/></div>


    <div className="form-group"  style={{cursor:"pointer" , width:"80%"}} >
    <input type="text" className="form-control" placeholder="Enter your option B"
       value={optB}
       onChange={(e)=>{setOptB(e.target.value)}}
    />
    </div>

</div>


<div className='d-flex flex-row gap-2'>
<div>
    <input  className="form-check-input"  style={{cursor:"pointer" , width:"20px" , height:"20px" ,marginTop:"7px"}} type="radio"  name="inlineRadioOptions" id="inlineRadio3" 
    onClick={()=>setChecked(3)}
/></div>

    <div className="form-group"  style={{cursor:"pointer" , width:"80%"}} >
    <input type="text" className="form-control" placeholder="Enter your option C"
       value={optC}
       onChange={(e)=>{setOptC(e.target.value)}}
    />
    </div>

</div>


<div className='d-flex flex-row gap-2'>
<div>
    <input  className="form-check-input"  style={{cursor:"pointer" , width:"20px" , height:"20px" ,marginTop:"7px"}} type="radio"  name="inlineRadioOptions" id="inlineRadio4"
    onClick={()=>setChecked(4)}
/></div>

    <div className="form-group"  style={{cursor:"pointer" , width:"80%"}} >
    <input type="text" className="form-control" placeholder="Enter your option D"
       value={optD}
       onChange={(e)=>{setOptD(e.target.value)}}
    />
    </div>

</div>

  </div>

<div style={{textAlign:"center" , marginTop:"1rem"}}>
  <button  type="button" className="btn btn-primary" onClick={addQuestionsData}>Add Question</button>
  </div>
  
</div>


    </>
  )
}

export default AddQuestion