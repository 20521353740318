import React, { useState, useRef, useEffect, useContext } from "react";
import { InfinitySpin } from "react-loader-spinner";
import Offcanvas from "react-bootstrap/Offcanvas";
import Content from "./Content";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "@ramonak/react-progress-bar";
import Quizzes from "./Quizzes";
import AddQuiz from "./AddQuiz";
import Details from "./Details";
import { token, base_url, PREREC_BASE_URL, IMAGE_URL } from "../env";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Context } from "../../index.js";
import { Typography } from "@mui/material";
import StudyMaterial from "./StudyMaterial.jsx";
import Form from "react-bootstrap/Form";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import Button from '@mui/material/Button';

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const PreTab = () => {
  const {refreshVideoPage, setRefreshVideoPage} = useContext(Context);
  const {refreshStudyPage, setRefreshStudyPage} = useContext(Context);
 
  const { preTagRefresh } = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [tabs, setTabs] = useState(0);
  const [progress, setProgress] = useState(0);
  const [videoTitle, setVideoTitle] = useState("");
  const [cardData, setCardData] = useState([]);

  const [selectedCardId, setSelectedCardId] = useState(null);
  const [courseName, setCourseName] = useState(null);

  

  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedVideoTitle, setSelectedVideoTitle] = useState("");
  const { videoData, setVideoData } = useContext(Context);
  const handleShow = (cardId, courseName) => {
    setSelectedCardId(cardId);
    setCourseName(courseName);
    setShow(true);
  };

  const getCourses = async () => {
    setIsLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${PREREC_BASE_URL}/client/get/courses`,
        requestOptions
      )
      const result = await response.json();
          if (result.status == 1) {
            setCardData(result.data);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
          }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
    // setProgress(1)
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    try {
      setProgress(1);
      // setVideoTitle("");
      setSelectedVideoTitle("");

      await new Promise((resolve) => setTimeout(resolve, 2000));

      setProgress(100);
      // setVideoTitle(selectedFile.name);
      setSelectedVideoTitle(selectedFile.name);
      setSelectedVideo(selectedFile);

      console.log("File uploaded:", selectedFile);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const addVideo = async () => {
    setIsLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var formdata = new FormData();
      formdata.append("title", videoTitle);
      formdata.append("course_id", selectedCardId);
      formdata.append("video", selectedVideo);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

     const response =  await fetch(
        `${PREREC_BASE_URL}/client/add/video/to/course`,
        requestOptions
      )

      response.onprogress = function(event) {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setProgress(progress); // Update progress state
        }
      };

      const result = await response.json();
          if (result.status == 1) {
            toast.success(result.message);
            handleClose1();
          
            setSelectedVideo("");
            setVideoTitle("");
            setProgress(0);
            setRefreshVideoPage(true);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
          } else if (result.status == "FILE_ERR") {
            toast.error(result.Backend_Error);
          }
    } catch (e) {
      console.log(e);
    }
    finally {
      setIsLoading(false);
    }
  };

  const [studyMaterialVideo, setStudyMaterialVideo] = useState("");
  const [open, setOpen] = React.useState(false);
  const [materialTitle, setMaterialTitle] = useState("");

  const handleChangeMui = (event) => {
    setStudyMaterialVideo(event.target.value);
  };

  const handleCloseMui = () => {
    setOpen(false);
  };

  const handleOpenMui = () => {
    setOpen(true);
  };

  const [file, setFile] = useState(null);

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const { studyMaterial, setStudyMaterial } = useContext(Context);

  const uploadStudyMaterial = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var formdata = new FormData();
      formdata.append("title", materialTitle);
      formdata.append("video_id", studyMaterialVideo);
      formdata.append("course_id", selectedCardId);
      formdata.append("pdf", file);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

     const response = await fetch(
        `${PREREC_BASE_URL}/client/studymaterials/upload/study/materials/in/video`,
        requestOptions
      )
      const result = await response.json();
          if (result.status == 1) {
            toast.success("material uploaded successfully");
            setMaterialTitle("");
            setStudyMaterialVideo("");
            setFile("");
            handleClose3();
            setRefreshStudyPage(true);
        
          } else if (result.status === 'VAL_ERR') { 
                    toast(result.Backend_Error) 
                  } 
                else if (result.status == 'FILE_ERR') { 
                    toast(result.Backend_Error) 
                  }else if (result.status == "TOKEN_ERR") { 
                    localStorage.removeItem('api_url'); 
                    Navigate('/code') 
                    toast("Logged in other device! Please login again") 
                  } 
                  else { 
                    console.log(result) 
                  }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCourses();
  }, [preTagRefresh]);

  return (
    <>
      <div className="container-fluid pb-4">
        <div className="row row-gap-4">
          {cardData <= 0 ? (
            <>
              {isLoading == true ? (
                <>
                  <div className="col-6 h-100 text-center w-100 mx-auto">
                    <InfinitySpin width="300" color="#3D70F5" />
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-5">
                    <h5 className="text-center mt-5">
                      No Courses Found , Please Add Courses !
                    </h5>
                  </div>
                </>
              )}
            </>
          ) : (
            cardData
              ?.slice()
              .reverse()
              .map((data, value) => (
                <div className="col-4" key={value}>
                  <div className="card h-100">
                    <img
                      src={`${IMAGE_URL}${data.banner}`}
                      alt="something"
                      className="w-100"
                      style={{
                        objectFit: "cover",
                        height: "200px",
                        objectPosition: "center center",
                      }}
                    />
                    <div className="px-2 mt-1">
                      <Typography
                        className="mb-0"
                        noWrap
                        style={{ fontSize: "1.3rem", fontWeight: 700 }}
                      >
                        {data.cou_name}
                      </Typography>
                      <p
                        className="mb-0"
                        style={{
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#8A8A8A",
                          
                        }}
                      >
                       Amount: 
                       <span
                              style={{
                                color: "red",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                textDecoration: data.discount > 0 ? "line-through" : "none",
                              }}
                            > ₹ {data.amount}</span>
                      </p>
                      <p
                        className="mb-0"
                        style={{
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#8A8A8A",
                        }}
                      >
                       Discount: 
                       <span
                              style={{
                                color: "red",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                              }}
                            > {data.discount} %</span>
                      </p>
                      <p
                        className="mb-0"
                        style={{
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#8A8A8A",
                        }}
                      >
                       Final Amount: 
                       <span
                              style={{
                                color: "red",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                              }}
                            > ₹ {data.final_amount}</span>
                      </p>
                      <div className="my-3">
                        <button
                          onClick={() => handleShow(data._id, data.cou_name)}
                          className="btn py-2 w-100 text-white"
                          style={{ background: "#2C62EE" }}
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>

      {/* view course details offcanvas started  */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ width: "40%" }}
      >
        <Offcanvas.Header className="d-flex align-items-center justify-content-between">
          {/* <div> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
            width="30"
            height="30"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M16.6666 10L18.9999 12.4167L13.0833 18.3333L33.3333 18.3333V21.6667L13.0833 21.6667L18.9999 27.5833L16.6666 30L6.66658 20L16.6666 10Z"
              fill="black"
            />
          </svg>
          <div className="d-flex align-items-center gap-2">
            <button
              className="py-1 px-2"
              style={{
                background: "#FFEFEF",
                borderRadius: "5px",
                color: "#f93333",
                border: "1px solid #FFEFEF",
                fontSize: "0.9rem",
              }}
            >
              Delete course
            </button>
            {/* <button
              className="py-1 px-2 bg-transparent"
              style={{
                borderRadius: "5px",
                color: "#2C62EE",
                border: "1px solid #2C62EE",
                fontSize: "0.9rem",
              }}
            >
              Save Changes
            </button> */}
          </div>
          {/* </div> */}
        </Offcanvas.Header>

        <Offcanvas.Header className=" border-bottom pt-0">
          <Offcanvas.Title className="ps-2" style={{ fontWeight: 700 }}>
            {courseName}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex align-items-center gap-3">
            <span
              onClick={() => setTabs(0)}
              className="px-2 pb-1"
              style={{
                borderBottom: tabs === 0 ? "3px solid #3D70F5" : "none",
                color: tabs === 0 ? "#3D70F5" : "#000",
                cursor: "pointer",
              }}
            >
              Content
            </span>
            {/* <span
              onClick={() => setTabs(1)}
              className="px-2 pb-1"
              style={{
                borderBottom: tabs === 1 ? "3px solid #3D70F5" : "none",
                color: tabs === 1 ? "#3D70F5" : "#000",
                cursor: "pointer",
              }}
            >
              Quizzes
            </span> */}
            <span
              onClick={() => setTabs(2)}
              className="px-2 pb-1"
              style={{
                borderBottom: tabs === 2 ? "3px solid #3D70F5" : "none",
                color: tabs === 2 ? "#3D70F5" : "#000",
                cursor: "pointer",
              }}
            >
              Details
            </span>
            <span
              onClick={() => setTabs(3)}
              className="px-2 pb-1"
              style={{
                borderBottom: tabs === 3 ? "3px solid #3D70F5" : "none",
                color: tabs === 3 ? "#3D70F5" : "#000",
                cursor: "pointer",
              }}
            >
              Study Materials
            </span>
          </div>

          <div className="mt-3">
            {tabs === 0 ? (
              <>
                <div className="d-flex justify-content-end align-items-end">
                  <button
                    className="py-1 px-2 bg-transparent"
                    style={{
                      borderRadius: "5px",
                      color: "#2C62EE",
                      border: "1px solid #2C62EE",
                      fontSize: "0.9rem",
                      fontWeight: 700,
                    }}
                    onClick={handleShow1}
                  >
                    + Add videos
                  </button>
                </div>

                <div
                  className="mt-2 example"
                  style={{ height: "61vh", overflowY: "scroll" }}
                >
                  <Content currentCourseId={selectedCardId} />
                </div>

                {/* add video model started  */}
                <Modal show={show1} onHide={handleClose1} centered>
                  <Modal.Header closeButton className="border-0"></Modal.Header>
                  <Modal.Body>
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <h5 style={{ fontWeight: 700 }}>Title</h5>
                          <input
                            type="text"
                            placeholder="Enter Title"
                            className="px-3 py-2 w-100 border-0"
                            style={{ borderRadius: "5px", background: "#eee" }}
                            onChange={(e) => setVideoTitle(e.target.value)}
                          />
                        </div>
                        <>
                        {isLoading && (
                        <div className="col-6 text-center">
                        <InfinitySpin width="300" color="#3D70F5"  />
                      </div>
                      )}
                        <div className="col-12 mt-3">
                          {progress === 0 ? (
                            <>
                              <h5 style={{ fontWeight: 700 }}>Select Video</h5>
                              <input
                                type="file"
                                accept="video/*"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />
                              <button
                                className="w-25 py-1 border-0"
                                style={{
                                  borderRadius: "5px",
                                  background: "#eee",
                                }}
                                onClick={handleButtonClick}
                              >
                                Upload
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 36 36"
                                  fill="none"
                                >
                                  <path
                                    d="M13.5 24H22.5V15H28.5L18 4.5L7.5 15H13.5V24ZM7.5 27H28.5V30H7.5V27Z"
                                    fill="#8D8D8D"
                                  />
                                </svg>
                              </button>
                            </>
                          ) : (
                            <>
                              {progress === 100 ? (
                                <>
                                  <h5 style={{ fontWeight: 700 }}>
                                    Uploaded Successfully &#10004;
                                  </h5>
                                </>
                              ) : (
                                <>
                                  <h5 style={{ fontWeight: 700 }}>
                                    Uploading Video
                                  </h5>
                                </>
                              )}
                              <ProgressBar
                                completed={progress}
                                bgColor="#3D70F5"
                                height="13px"
                                labelAlignment="right"
                                isLabelVisible={true}
                                labelColor="#fff"
                                labelSize="10px"
                                animateOnRender
                                maxCompleted={100}
                              />
                              <p className="mt-1">{videoTitle}</p>
                            </>
                          )}
                        </div>
                        </>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="border-0 justify-content-center">
                    <button
                      className="btn border-0  py-2 text-white w-50"
                      style={{ background: "#3D70F5" }}
                      disabled={progress < 100 || isLoading}
                      onClick={() => {
                        if (videoTitle.length <= 0) {
                          toast.error("pls enter title");
                        } else if (selectedVideo.length <= 0) {
                          toast.error("pls select video");
                        } else {
                          addVideo();
                          
                        }
                      }}
                    >
                      Confirm
                    </button>
                  </Modal.Footer>
                </Modal>
                {/* add video model end  */}
              </>
            ) : (
              <></>
            )}

            {tabs === 1 ? (
              <>
                <div className="d-flex justify-content-end align-items-end">
                  <button
                    className="py-1 px-2 bg-transparent"
                    style={{
                      borderRadius: "5px",
                      color: "#2C62EE",
                      border: "1px solid #2C62EE",
                      fontSize: "0.9rem",
                      fontWeight: 700,
                    }}
                    onClick={handleShow2}
                  >
                    + Add Quizzes
                  </button>
                </div>
                <div
                  className="mt-2 example"
                  style={{ height: "61vh", overflowY: "scroll" }}
                >
                  <Quizzes currentCourseId={selectedCardId} />
                </div>

                {/* add quizzes offcanvas started  */}
                <Offcanvas
                  show={show2}
                  onHide={handleClose2}
                  placement="end"
                  style={{ width: "40%" }}
                >
                  <Offcanvas.Header closeButton></Offcanvas.Header>
                  <Offcanvas.Body>
                    <AddQuiz
                      handleClose2={handleClose2}
                      currentCourseId={selectedCardId}
                      currentCourseName={courseName}
                    />
                  </Offcanvas.Body>
                </Offcanvas>
                {/* add quizzes offcavnas end  */}
              </>
            ) : (
              <></>
            )}
            {tabs === 2 ? (
              <>
                <div
                  className="mt-2 example"
                  style={{ height: "68vh", overflowY: "scroll" }}
                >
                  <Details currentCourseId={selectedCardId} />
                </div>
              </>
            ) : (
              <></>
            )}

            {tabs === 3 ? (
              <>
                <div className="d-flex justify-content-end align-items-end">
                  <button
                    className="py-1 px-2 bg-transparent"
                    style={{
                      borderRadius: "5px",
                      color: "#2C62EE",
                      border: "1px solid #2C62EE",
                      fontSize: "0.9rem",
                      fontWeight: 700,
                    }}
                    onClick={handleShow3}
                  >
                    + Add Material
                  </button>
                </div>
                <div
                  className="mt-2 example"
                  style={{ height: "61vh", overflowY: "scroll" }}
                >
                  <StudyMaterial currentCourseId={selectedCardId} />
                </div>

                {/* add study material modal started  */}
                <Modal show={show3} onHide={handleClose3} centered>
                  <Modal.Header closeButton className="border-0">
                    {courseName}
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Enter Title</Form.Label>
                        <Form.Control
                          onChange={(e) => setMaterialTitle(e.target.value)}
                          type="text"
                          placeholder="material-name"
                        />
                      </Form.Group>
                      
                      
                      <FormControl fullWidth sx={{ mb: 0 }}>
                        <InputLabel id="demo-controlled-open-select-label">
                          Select Video
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          open={open}
                          onClose={handleCloseMui}
                          onOpen={handleOpenMui}
                          value={studyMaterialVideo}
                          label="Select Video"
                          onChange={handleChangeMui}
                        >
                          {videoData.length > 0 ? (
                            videoData
                              .slice()
                              .reverse()
                              .map((data, value) => (
                                <MenuItem key={value} value={data._id}>
                                  {data.title}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem value="">
                              <em>No Video found pls add first!</em>
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                     

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm1.ControlInput11"
                      >
                        {/* <Form.Label>Upload Material</Form.Label> */}
                        <br />
                        <Button
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload PDF
                          <VisuallyHiddenInput
                            type="file"
                            accept=".pdf"
                            onChange={handleFileUpload}
                          />
                        </Button>
                      </Form.Group>

                      <Button fullWidth onClick={()=>{
                        if(materialTitle<=0){
                          toast.error("pls enter material title");
                        }else if(studyMaterialVideo<=0){
                          toast.error("pls select video");
                        }else if(file<=0){
                          toast.error("pls select material");
                        }else{
                          uploadStudyMaterial();
                        }
                      }} variant="contained" color="primary" >Proceed</Button>
                    </Form>
                  </Modal.Body>
                </Modal>
                {/* add study material  modal end  */}
              </>
            ) : (
              <></>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* view course details offcanvas end  */}
      <Toaster position="top-right" />
    </>
  );
};

export default PreTab;
