import React, { useState, useEffect, useContext } from "react";
import { InfinitySpin } from "react-loader-spinner";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { base_url } from "../env";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import PaymentsTable from "./PaymentsTable";

const ViewUserDetails = () => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const [usersData, setUserData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const getUserDetails = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        user_id: Number(id),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
      };

     const response = await fetch(
        `${base_url}/auth/admin/users/view/profile/of/particular/user`,
        requestOptions
      )
      const result = await response.json();
          if (result.status == 1) {
            setUserData(result.data);
            console.log("self ", result.data);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
          }
    } catch (err) {
      console.log(err);
    }
  };

  const getPaymentDetails = async () => {
    try {
      setIsLoading(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        user_id: Number(id),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
      };

     const response = await fetch(
        `${base_url}/auth/admin/users/view/transaction/of/particular/user`,
        requestOptions
      )
      const result = await response.json();
          if (result.status == 1) {
            setPaymentData(result.data);
            setTotalAmount(result.totalAmount);
            setIsLoading(false)
            console.log("payment ", result.data);
            console.log("totAmount", result.totalAmount);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
          }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPaymentDetails();
    getUserDetails();
  }, []);

  return (
    <>
      <div
        className="container-fluid"
        style={{ height: "100vh", overflowY: "hidden" }}
      >
        <div className="row h-100">
          <div className="col-2 h-100 p-0">
            <Sidebar />
          </div>

          {/* Main Content */}
          <div className="col-10 h-100 p-0">
            <div style={{ height: "10%", background: "#2C62EE" }}>
              <Header />
            </div>

            {/* mid part */}
            <div
              style={{ height: "20%", margin: "5px", backgroundColor: "white" }}
              className=" p-1 d-flex flex-row gap-5 rounded"
            >
              <img
                src={usersData.profile}
                alt="something"
                style={{ height: "100%", borderRadius: "50%" }}
              />

              <div
                className="d-flex flex-column flex-grow-1"
                style={{ justifyContent: "center", height: "100%" }}
              >
                <div style={{ fontSize: "2rem" }}> {usersData.name}</div>

                <div
                  className="d-flex flex-row gap-5 "
                  style={{ fontSize: "1rem", fontWeight: 400 }}
                >
                  <div>
                    Mobile :{" "}
                    <span
                      style={{ color: "#87CEEB" }}
                    >{`+91 ${usersData.mobile}`}</span>
                  </div>
                  <div>
                    Email :{" "}
                    <span style={{ color: "#87CEEB" }}>{usersData.email}</span>
                  </div>
                </div>

                <hr />

                <div style={{ fontSize: "1.5rem", fontWeight: 500 }}>
                  Total Revenue :
                  <span style={{ color: "red" }}>{` ₹ ${totalAmount}`}</span>
                </div>
              </div>
            </div>

            <div
              style={{ height: "70%", overflowY: "auto", overflowX: "hidden" }}
              className="p-1 pt-4"
            >
              {isLoading == true ? (
                <div className="col-6 h-100 text-center w-100 mx-auto">
                  <InfinitySpin width="300" color="#3D70F5" />
                </div>
              ) : paymentData?.length === 0 ? (
                <>
                  <div className="mt-5">
                    <h5 className="text-center mt-5">
                      No Transactions Details found !
                    </h5>
                  </div>
                </>
              ) : (
                <PaymentsTable data={paymentData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewUserDetails;
