import React, { useState, useEffect, useContext } from "react";
import { InfinitySpin } from "react-loader-spinner";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { base_url } from "../env";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";

const UsersMain = () => {
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const [usersData, setUsersData] = useState([]);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${base_url}/auth/admin/users/open/user/tab`,
        requestOptions
      );
      const result = await response.json();
      if (result.status == 1) {
        setUsersData(result.users);
        console.log(result.users);
      } else if (result.status == "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
      } else {
        toast.error(result.bakend_error);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <div
        className="container-fluid"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <div className="row h-100">
          <div className="col-2 h-100 p-0">
            <Sidebar />
          </div>

          {/* Main Content */}
          <div className="col-10 h-100 p-0">
            <div style={{ height: "10%", background: "#2C62EE" }}>
              <Header />
            </div>

            <div
              style={{ height: "90%", overflowY: "auto" }}
              className="p-1 example row row-gap-4"
            >
              {usersData <= 0 ? (
                <>
                  {isLoading == true ? (
                    <>
                      <div className="col-6 h-100 text-center w-100 mx-auto">
                        <InfinitySpin width="300" color="#3D70F5" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mt-5">
                        <h5
                          className="text-center mt-5"
                          style={{ color: "red" }}
                        >
                          No Users Found
                        </h5>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <Table responsive className="border">
                  <thead>
                    <tr style={{ fontSize: "1.2rem" }}>
                      <th>S.No</th>
                      <th>Profile</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {usersData.map((user, index) => (
                      <tr>
                        <td className="align-middle">{index + 1}</td>
                        <td className="align-middle">
                          <img
                            src={user.profile}
                            className="rounded-circle"
                            style={{ width: "3rem", height: "3rem" }}
                          />
                        </td>
                        <td className="align-middle">{user.name}</td>
                        <td className="align-middle">{user.email}</td>
                        <td className="align-middle">{user.mobile}</td>
                        <td className="align-middle">
                          <button
                            className="btn btn-primary py-1"
                            onClick={() => {
                              Navigate(`ViewUser/${user.id}`);
                            }}
                          >
                            View Profile
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersMain;
