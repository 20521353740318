import React, { useState, useRef, useContext } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import PreTab from "./PreTab";
import QuestionTab from "./QuestionTab";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { token, base_url, QUIZ_BASE_URL, PREREC_BASE_URL } from "../env.js";
import toast, { Toaster } from "react-hot-toast";
import FreeCoursesTab from "./FreeCoursesTab.js";
import { Context } from "../../index.js";
import { InfinitySpin } from "react-loader-spinner";

const Main = () => {
  const {
    setPreTagRefresh,
    preTagRefresh,
    setFreeCoursesRefresh,
    freeCoursesRefresh,
  } = useContext(Context);
  const [getThumbnail, setGetThumbnail] = useState("");
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const inputFileRef = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tab, setTab] = useState(0);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [def, setDef] = useState("");
  const [banner, setBanner] = useState(null);
  const [universal, setUniversal] = useState(0);
  const [filterCategory, setFilterCategory] = useState([]);
  const [duration, setDuration] = useState("never");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [universalHandler, setUniversalHandler] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  // const [isFree, setIsFree] = useState(true);
  const [free, setFree] = useState("1");

  const handleProfileChange = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setDef(fileUrl);
      setBanner(fileUrl);
    } else {
      setBanner(def);
    }
  };

  const addCourse = async () => {
    setAddLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var formdata = new FormData();
      formdata.append("cou_name", name);
      if (free == "0") {
        formdata.append("amount", price);
        formdata.append("discount", discount);
      }

      formdata.append("banner", banner);
      formdata.append("free", free);
      formdata.append("Duration", duration);
      formdata.append("category_id", selectedCategoryId);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      const response = await fetch(
        `${PREREC_BASE_URL}/client/create/course`,
        requestOptions
      );
      const result = await response.json();
      if (result.status == 1) {
        toast.success(result.msg);
        handleClose();
        setName("");
        setPrice("");
        setGetThumbnail("");
        setBanner(null);
        setDuration("never");
        setSelectedCategoryId("");
        setDef("");
        setFree("1");
        setPreTagRefresh(!preTagRefresh);
        setFreeCoursesRefresh(!freeCoursesRefresh);
        setDiscount("");
      } else if (result.status === "VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == "FILE_VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
        toast("Logged in other device! Please login again");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setAddLoading(false);
    }
  };

  // Get all categories
  const APi3 = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${PREREC_BASE_URL}/client/get/category`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setFilterCategory(result.categories);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {}
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{ height: "100vh", overflowY: "hidden" }}
      >
        <div className="row h-100">
          {/* <div className="col-2 h-100 p-0">
            <Sidebar />
          </div> */}
          <div className="col-12 h-100 p-0">
            <div style={{ height: "10%", background: "#2C62EE" }}>
              <Header />
            </div>

            <div
              className=""
              style={{ height: "20%", borderBottom: "1px solid #E4E4E4" }}
            >
              <div className="d-flex flex-column justify-content-end h-100">
                <div className="d-flex align-item-center justify-content-between px-3 mb-3">
                  {/* tabs */}
                  {tab === 1 ? (
                    <>
                      <p
                        className="mb-0"
                        style={{
                          fontSize: "1.5rem",
                          color: "#3c3c3c",
                          fontWeight: 600,
                        }}
                      >
                        Question Bank
                      </p>
                    </>
                  ) : (
                    <>{/* <p className="mb-0"></p> */}</>
                  )}

                  {tab === 0 ? (
                    <>
                      <p
                        className="mb-0"
                        style={{
                          fontSize: "1.5rem",
                          color: "#3c3c3c",
                          fontWeight: 600,
                        }}
                      >
                        Pre-recorded course
                      </p>
                    </>
                  ) : (
                    <>{/* <p className="mb-0"></p> */}</>
                  )}

                  {tab === 2 ? (
                    <>
                      <p
                        className="mb-0"
                        style={{
                          fontSize: "1.5rem",
                          color: "#3c3c3c",
                          fontWeight: 600,
                        }}
                      >
                        Free Courses
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="mb-0"></p>
                    </>
                  )}

                  {tab === 1 ? (
                    <>
                      <button
                        className="btn btn-primary px-4"
                        style={{ background: "#2C62EE", fontSize: "1rem" }}
                        onClick={() => Navigate("/AddTag")}
                      >
                        + Add Tag
                      </button>
                    </>
                  ) : (
                    <></>
                  )}

                  {tab === 0 ? (
                    <>
                      <button
                        className="btn btn-primary px-4"
                        onClick={() => {
                          handleShow();
                          APi3();
                        }}
                        style={{ background: "#2C62EE", fontSize: "1rem" }}
                      >
                        + Add Course
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="d-flex align-items-center justify-content-between mb-0">
                  <div className=" d-flex align-items-center gap-3">
                    <span
                      className="py-2 px-3"
                      onClick={() => setTab(0)}
                      style={{
                        cursor: "pointer",
                        borderBottom: tab === 0 ? "2px solid #3D70F5" : "none",
                      }}
                    >
                      Pre-recorded course
                    </span>
                    {/* <span
                      className="py-2 px-3"
                      onClick={() => setTab(1)}
                      style={{
                        cursor: "pointer",
                        borderBottom: tab === 1 ? "2px solid #3D70F5" : "none",
                      }}
                    >
                      Question Bank
                    </span> */}

                    {/* myspan */}
                    <span
                      className="py-2 px-3"
                      onClick={() => setTab(2)}
                      style={{
                        cursor: "pointer",
                        borderBottom: tab === 2 ? "2px solid #3D70F5" : "none",
                      }}
                    >
                      Free Courses
                    </span>
                  </div>

                  {/* <div
                    className="input-group w-25 pe-3"
                    style={{ backgroundColor: "#F1F1F1 !important" }}
                  >
                    <span
                      className="input-group-text border-0"
                      id="basic-addon1"
                      style={{ background: "#F1F1F1" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M12.9167 11.6667H12.2583L12.025 11.4417C12.8699 10.4617 13.3343 9.21058 13.3333 7.91667C13.3333 6.84535 13.0157 5.7981 12.4205 4.90733C11.8253 4.01656 10.9793 3.3223 9.98954 2.91232C8.99977 2.50235 7.91066 2.39508 6.85993 2.60408C5.8092 2.81309 4.84404 3.32897 4.08651 4.08651C3.32897 4.84404 2.81309 5.8092 2.60408 6.85993C2.39508 7.91066 2.50235 8.99977 2.91232 9.98954C3.3223 10.9793 4.01656 11.8253 4.90733 12.4205C5.7981 13.0157 6.84535 13.3333 7.91667 13.3333C9.25834 13.3333 10.4917 12.8417 11.4417 12.025L11.6667 12.2583V12.9167L15.8333 17.075L17.075 15.8333L12.9167 11.6667ZM7.91667 11.6667C5.84167 11.6667 4.16667 9.99167 4.16667 7.91667C4.16667 5.84167 5.84167 4.16667 7.91667 4.16667C9.99167 4.16667 11.6667 5.84167 11.6667 7.91667C11.6667 9.99167 9.99167 11.6667 7.91667 11.6667Z"
                          fill="#9E9E9E"
                        />
                      </svg>
                    </span>
                    <input
                      style={{ backgroundColor: "#F1F1F1" }}
                      type="text"
                      className="form-control border-0"
                      placeholder="Search..."
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div> */}
                </div>
              </div>
            </div>

            <div
              style={{ height: "70vh", overflowY: "auto" }}
              className="p-3 example"
            >
              {tab === 0 ? (
                <>
                  <PreTab />
                </>
              ) : (
                <></>
              )}
              {tab === 1 ? (
                <>
                  <QuestionTab />
                </>
              ) : (
                <></>
              )}

              {tab === 2 ? (
                <>
                  <FreeCoursesTab />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* add course offcanvas started */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ width: "35%" }}
      >
        <Offcanvas.Header
          closeButton
          style={{ borderBottom: "1px solid black" }}
        >
          <Offcanvas.Title style={{ fontSize: "1.2rem", fontWeight: 700 }}>
            Add New Course
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="example">
          <div>
            {/* add banner image  */}
            <div>
              <h6>Add Banner Image</h6>
              <div
                style={{
                  backgroundColor: "#F0F0F0",
                  padding: "10px",
                  borderRadius: "5px",
                  width: "360px",
                }}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={inputFileRef} // Set the ref to the file input
                  accept=".png,.jpeg,.jpg" // Allow only image files
                  onChange={(e) => {
                    setBanner(e.target.files[0]);
                    setDef(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                <button
                  className="btn btn-secondary mt-1"
                  style={{ width: "340px" }}
                  onClick={handleProfileChange} // Trigger click event on button click
                >
                  Choose Image
                </button>

                {def && (
                  <div className="mt-2">
                    <img
                      src={def}
                      alt="Selected"
                      style={{
                        width: "340px",
                        height: "190px",
                        objectFit: "cover",
                        objectPosition: "center center",
                      }}
                    />
                  </div>
                )}

                <p
                  className="mt-2 mb-0 text-center"
                  style={{ fontSize: "0.8rem", color: "#6C757D" }}
                >
                  Image resolution: 340x190
                </p>
              </div>
            </div>

            {/* enter course name  */}
            <div className="mt-3">
              <h6>Enter Course Name</h6>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Start Typing Course Name..."
                className="px-3 py-2"
                style={{
                  border: "1.369px solid #BFBFBF",
                  borderRadius: "8px",
                  width: "70%",
                }}
              />
            </div>

            {/* radio buttons */}
            <div className=" my-2">
              <h6 className="mt-3">Select Course Type</h6>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  checked={free === "1"}
                  style={{ cursor: "pointer" }}
                  type="radio"
                  defaultChecked={free == 1}
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="1"
                  onChange={(e) => {
                    setFree(e.target.value);
                  }}
                />
                <label className="form-check-label" for="inlineRadio1">
                  Free
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  defaultChecked={free == 0}
                  checked={free === "0"}
                  style={{ cursor: "pointer" }}
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="0"
                  onChange={(e) => {
                    setFree(e.target.value);
                  }}
                />
                <label className="form-check-label" for="inlineRadio2">
                  Paid
                </label>
              </div>
            </div>

            {/* checkbox for upload brainbucks */}

            {/* <div class="form-check form-switch my-2">
  <input class="form-check-input" type="checkbox" style={{cursor:"pointer"}}
  
  onClick={()=>{
    setUniversalHandler(!universalHandler)
  
    if (universalHandler) {
      setUniversal(0)
    } else {
      setUniversal(1)
    }
  
  }}

  />
  <label class="form-check-label" for="mySwitch">Upload On BrainBucks ? </label>
</div> */}

            {/* Duration of Courses */}
            <div className="row mt-4">
              <p
                className="col-3 mt-1"
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#303030",
                }}
              >
                Duration
              </p>

              <select
                className="col-3 rounded"
                value={duration}
                style={{
                  border: "1px solid #000000",
                  height: "30px",
                }}
                onChange={(e) => setDuration(e.target.value)}
              >
                <option value="never">never</option>
                <option value="5 mins">5 mins</option>
                <option value="15 mins">15 mins</option>
                <option value="30 mins">30 mins</option>
                <option value="45 mins">45 mins</option>
                <option value="1 hrs">1 hrs</option>
                <option value="2 hrs">2 hrs</option>
                <option value="3 hrs">3 hrs</option>
                <option value="6 hrs">6 hrs</option>
                <option value="1 days">1 days</option>
                <option value="2 days">2 days</option>
                <option value="3 days">3 days</option>
                <option value="1 week">1 week</option>
                <option value="2 week">2 week</option>
                <option value="1 month">1 month</option>
                <option value="2 month">2 month</option>
                <option value="3 month">3 month</option>
                <option value="6 month">6 month</option>
                <option value="1 year">1 year</option>
              </select>
            </div>
            {/* 
                Select Categories */}
            <div className="row mt-4">
              <p
                className="col-3 mt-1"
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#303030",
                }}
              >
                Category
              </p>

              <select
                className="col-5 rounded"
                value={selectedCategoryId}
                style={{
                  border: "1px solid #000000",
                  height: "30px",
                }}
                onChange={(e) => setSelectedCategoryId(e.target.value)}
              >
                <option hidden={true} key={0} value={""}>
                  --Select Category--
                </option>
                {filterCategory?.map((res) => (
                  <option key={res?._id} value={res?._id}>
                    {res?.category_name}
                  </option>
                ))}
              </select>
            </div>

            {free == 0 ? (
              <>
                <div className="mt-3">
                  <h6>Enter Course Price</h6>
                  <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="₹ 5,000"
                    className="px-3 py-2"
                    style={{
                      border: "1.369px solid #BFBFBF",
                      borderRadius: "8px",
                      width: "70%",
                    }}
                  />
                </div>

                <div className="mt-3">
                  <h6>Enter Discount</h6>
                  <input
                    type="number"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    placeholder="20%"
                    className="px-3 py-2"
                    style={{
                      border: "1.369px solid #BFBFBF",
                      borderRadius: "8px",
                      width: "70%",
                    }}
                  />
                </div>

                {/* final amount  */}
                {/* <div className="mt-3">
              <h6>
                Final Amount :{" "}
                <span style={{ fontWeight: 700 }}> ₹ {price}</span>
              </h6>
            </div> */}
              </>
            ) : (
              <></>
            )}

            {/* add course button  */}
            <div className="mt-3 mx-auto text-center">
              <button
                onClick={() => {
                  if (banner <= 0) {
                    toast.error("pls select banner image");
                  } else if (name <= 0) {
                    toast.error("pls enter course name");
                  } else if (price <= 0 && free == "0") {
                    toast.error("pls enter course price");
                  } else if (!selectedCategoryId) {
                    toast.error("pls select category");
                  } else {
                    addCourse();
                  }
                }}
                className="btn text-white w-50"
                style={{ background: "#2C62EE" }}
                disabled={addLoading}
              >
                {addLoading ? (
                  "Please Wait"
                ) : (
                  "Add Course"
                )}{" "}
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/*  add course offcanvas end  */}

      {/* add course success model started  */}
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className="py-5 text-center my-5 pt-0">
          New course has been successfully added !
        </Modal.Body>
      </Modal>
      {/* add course success model end  */}

      <Toaster position="top-right" />
    </>
  );
};

export default Main;
