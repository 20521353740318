import React, { useState, useEffect, useContext } from "react";
import { InfinitySpin } from "react-loader-spinner";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { base_url } from "../env";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import BySelfTable from "./BySelfTable";
import Card from "react-bootstrap/Card";
import ByBrainBucksTable from "./ByBrainBucksTable";

const ViewDetails = () => {
  let { id, cou_name, total_amount, total_users } = useParams();
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const Navigate = useNavigate();
  const [tab, setTab] = useState(0);

  const [brainBucksData, setBrainBucksData] = useState([]);
  const [totalUsersBySelf, setTotalUsersBySelf] = useState(0);
  const [totalRevenueBySelf, setTotalRevenueBySelf] = useState(0);

  const [selfData, setSelfData] = useState([]);
  const [totalUsersByBB, setTotalUsersByBB] = useState(0);
  const [totalRevenueByBB, setTotalRevenueByBB] = useState(0);

  const getBySelf = async () => {
    setIsLoading2(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        prereccou_id: Number(id),
        cou_name: String(cou_name),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
      };

      const response = await fetch(
        `${base_url}/auth/admin/accounts/courses/sell/by/self`,
        requestOptions
      );
      const result = await response.json();
      if (result.status == 1) {
        setSelfData(result.data);
        console.log("self ", result);

        let totalABySelf = 0;

        result.data.forEach((e) => {
          if (e.amount !== null) {
            totalABySelf += e.amount;
          }
        });
        setTotalUsersBySelf(result.data.length);
        setTotalRevenueBySelf(totalABySelf);
      } else if (result.status == "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading2(false);
    }
  };

  const getByBrainBucks = async () => {
    setIsLoading1(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        prereccou_id: Number(id),
        cou_name: String(cou_name),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${base_url}/auth/admin/accounts/courses/sell/by/brainbucks`,
        requestOptions
      );
      const result = await response.json();
      if (result.status == 1) {
        setBrainBucksData(result.data);
        console.log("brainbucks ", result);

        let totalAByBB = 0;

        result.data.forEach((e) => {
          if (e.amount !== null) {
            totalAByBB += e.amount;
          }
        });
        setTotalUsersByBB(result.data.length);
        setTotalRevenueByBB(totalAByBB);
      } else if (result.status == "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading1(false);
    }
  };

  useEffect(() => {
    getByBrainBucks();
    getBySelf();
  }, []);

  return (
    <>
      <div
        className="container-fluid"
        style={{ height: "100vh", overflowY: "hidden" }}
      >
        <div className="row h-100">
          <div className="col-2 h-100 p-0">
            <Sidebar />
          </div>

          {/* Main Content */}
          <div className="col-10 h-100 p-0">
            <div style={{ height: "10%", background: "#2C62EE" }}>
              <Header />
            </div>

            {/* mid part */}
            <div
              style={{
                height: "20%",
                margin: "5px",
                borderBottom: "2px solid black",
              }}
              className=" py-3  d-flex flex-row justify-content-around"
            >
              <Card
                style={{
                  width: "18rem",
                  textAlign: "center",
                  backgroundColor: "#2C62EF",
                  color: "white",
                }}
              >
                <Card.Body>
                  <Card.Title>Department</Card.Title>
                  <Card.Subtitle style={{ color: "wheat" }} className="mb-2 ">
                    {cou_name}
                  </Card.Subtitle>
                </Card.Body>
              </Card>

              <Card
                style={{
                  width: "18rem",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#2C62EF",
                }}
              >
                <Card.Body>
                  <Card.Title>Total Revenue</Card.Title>
                  <Card.Subtitle style={{ color: "wheat" }} className="mb-2 ">
                    ₹ {tab === 0 ? totalRevenueByBB : totalRevenueBySelf}
                  </Card.Subtitle>
                </Card.Body>
              </Card>

              <Card
                style={{
                  width: "18rem",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#2C62EF",
                }}
              >
                <Card.Body>
                  <Card.Title>Total Enrollments</Card.Title>
                  <Card.Subtitle style={{ color: "wheat" }} className="mb-2 ">
                    {tab === 0 ? totalUsersByBB : totalUsersBySelf}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>

            {/* bottom */}
            <div className=" d-flex align-items-center gap-3">
              <span
                className="py-2 px-3"
                onClick={() => setTab(0)}
                style={{
                  cursor: "pointer",
                  borderBottom: tab === 0 ? "2px solid #3D70F5" : "none",
                }}
              >
                By BrainBucks
              </span>
              <span
                className="py-2 px-3"
                onClick={() => setTab(1)}
                style={{
                  cursor: "pointer",
                  borderBottom: tab === 1 ? "2px solid #3D70F5" : "none",
                }}
              >
                By Self
              </span>
            </div>

            <div
              style={{ height: "70%", overflowY: "auto", overflowX: "hidden" }}
              className="p-1"
            >
              {tab === 0 && (
                <>
                  {isLoading1 == true ? (
                    <div className="col-6 h-100 text-center w-100 mx-auto">
                      <InfinitySpin width="300" color="#3D70F5" />
                    </div>
                  ) : brainBucksData?.length === 0 ? (
                    <>
                      <div className="mt-5">
                        <h5 className="text-center mt-5">No Details found !</h5>
                      </div>
                    </>
                  ) : (
                    <ByBrainBucksTable users={brainBucksData} />
                  )}
                </>
              )}

              {tab === 1 && (
                <>
                  {isLoading2 == true ? (
                    <div className="col-6 h-100 text-center w-100 mx-auto">
                      <InfinitySpin width="300" color="#3D70F5" />
                    </div>
                  ) : selfData?.length === 0 ? (
                    <>
                      <div className="mt-5">
                        <h5 className="text-center mt-5">No Details found !</h5>
                      </div>
                    </>
                  ) : (
                    <BySelfTable users={selfData} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDetails;
