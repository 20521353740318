import React from 'react';
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./components/pre-recorded/Main";
// import BannerMain from "./components/banner/BannerMain";
import AddTag from "./components/pre-recorded/AddTag";
import Login from "./components/login/Login";
import Authentication from "./Authentication";
import BannerMain from "./components/banner/BannerMain";
import TagDetails from './components/pre-recorded/TagDetails';
import AddQuestion from './components/pre-recorded/AddQuestion';
import AccountsMain from './components/accounts/AccountsMain';
import ViewDetails from './components/accounts/ViewDetails';
import UsersMain from './components/users/UsersMain';
import ViewUserDetails from './components/users/ViewUserDetails';

function App() {


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/" element={<Authentication />}>
            <Route path="/PreRecorded" element={<Main />} />
            <Route path="/PreRecorded/tagDetails/:_id" element={<TagDetails />} />
            <Route path="/PreRecorded/tagDetails/:_id/AddQuestion/" element={<AddQuestion/>} />
            <Route path="/AddTag" element={<AddTag />} />
            <Route path="/Accounts" element={<AccountsMain />} />
            <Route path="/Accounts/ViewCourse/:id/:cou_name/:total_amount/:total_users" element={<ViewDetails />} />

            {/* banner routes */}
            <Route path="/Banner" element={<BannerMain />} />
            <Route path="/Users" element={<UsersMain />} />
            <Route path="/Users/ViewUser/:id" element={<ViewUserDetails />} />


          </Route>
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
