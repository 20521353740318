import React from 'react'
import Table from 'react-bootstrap/Table';
import moment from "moment/moment";

const PaymentsTable = ({data}) => {
  return (
    <>
    <div className='text-center'>

    <h2 >User Transactions</h2>
    </div>
    
    <Table responsive className='border'>


  <thead>
    <tr style={{fontSize:"1.2rem"}}>
      <th>S.No</th>
      <th>Course</th>
      <th>Created On</th>
      <th>Amount</th>

    </tr>
  </thead>


  <tbody>


   

    {data.map((user , index)=>(
         <tr>
        <td>{index+1}</td>
        <td>{user.prereccou_name}</td>
        <td>{moment(user.createdOn).format("DD-MM-YYYY")}</td>
        <td> ₹ {user.amount}</td>


        </tr>
    ))}

   

  </tbody>



</Table>
</>
  )
}

export default PaymentsTable