import { InfinitySpin } from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import { token, base_url } from "../env.js";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const QuestionTab = () => {
  const [cardData, setCardData] = useState([]);
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // api for getting courses
  const getFormData = async () => {
    setIsLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );
      myHeaders.append("Data-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${base_url}/quiz/prerec_topic/get/pre/rec/tag`,
        requestOptions
      );
      const result = await response.json();
      if (result.status == 1) {
        setCardData(result.topics);
      }
      if (result.status === "VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == "FILE_VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
        toast("Logged in other device! Please login again");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFormData();
  }, []);

  return (
    <>
      <div className="container-fluid pb-4">
        <div className="row row-gap-4">
          {cardData.length <= 0 ? (
            <>
              {isLoading == true ? (
                <>
                  <div className="col-6 h-100 text-center w-100 mx-auto">
                    <InfinitySpin width="300" color="#3D70F5" />
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-5">
                    <h5 className="text-center mt-5">
                      No Tags Found , Please Add Tags !
                    </h5>
                  </div>
                </>
              )}
            </>
          ) : (
            cardData.map((data, value) => (
              <div className="col-4" key={value}>
                <div className="card h-100 p-2">
                  <p
                    className="w-75 text-center py-1"
                    style={{
                      background: "#F1F1F1",
                      borderRadius: "5px",
                      fontSize: "1.25rem",
                    }}
                  >
                    {data.topic_name}
                  </p>
                  <p style={{ fontSize: "1rem", color: "#474747" }}>
                    Total Question{" "}
                    <span style={{ color: "#F93333" }}>
                      {data.totalQuestions}
                    </span>
                  </p>
                  <p style={{ fontSize: "1rem", color: "#474747" }}>
                    Course{" "}
                    <span style={{ color: "#000", fontWeight: 700 }}>
                      {data.prerec_couname}
                    </span>
                  </p>
                  {/* <p style={{fontSize:"1rem",color:"#474747"}}>Subject <span  style={{color:"#000",fontWeight:700}}>{data.subject}</span></p> */}
                  <div className="my-1">
                    <button
                      className="btn py-2 w-100 text-white"
                      style={{ background: "#2C62EE" }}
                      // check for id name
                      onClick={() => Navigate(`tagDetails/${data._id}`)}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default QuestionTab;
