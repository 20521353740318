import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import Slider from "@mui/material/Slider";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import { token, base_url } from "../env";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const AddQuiz = ({ handleClose2, currentCourseId, currentCourseName }) => {
  const colors = ["#2C62EE", "#C922E4", "#CC1313", "#FF8E09"];
  const Navigate = useNavigate();
  const [progress, setProgress] = useState(1);
  const [slider4, setSlider4] = useState();
  const [quizRules, setQuizRules] = useState("");
  const [quizTime, setQuizTime] = useState("");
  const [quizName, setQuizName] = useState("");
  const [quizSpec, setQuizSpec] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [Times, setTimes] = useState();
  const [videoData, setVideoData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [marksPerQues, setMarksPerQues] = useState();

  const [selectedValue, setSelectedValue] = useState("");
  const [selectedVideoId, setSelectedVideoId] = useState("");

  const handleRadioChange = (value, id) => {
    setSelectedValue(value);
    setSelectedVideoId(id);
  };

  const [selectedTagsIds, setSelectedTagsIds] = useState([]);
  const [selectedTagsNames, setSelectedTagsNames] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  function handleChangeName(id, value) {
    setSelectedTags((prevTags) => {
      const updatedTags = prevTags.map((tag) =>
        tag.id === id ? { ...tag, value } : tag
      );

      const totalValue = updatedTags.reduce(
        (total, updatedTag) => total + updatedTag.value,
        0
      );

      if (totalValue > 100) {
        const adjustmentRatio = 100 / totalValue;
        return updatedTags.map((tag) => ({
          ...tag,
          value: Math.floor(tag.value * adjustmentRatio),
        }));
      }

      return updatedTags;
    });
  }

  const handleCheckboxChange = (id, topicName) => {
    setSelectedTagsIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((prevId) => prevId !== id)
        : [...prevIds, id]
    );

    setSelectedTagsNames((prevNames) =>
      prevNames.includes(topicName)
        ? prevNames.filter((prevName) => prevName !== topicName)
        : [...prevNames, topicName]
    );

    setSelectedTags((prevTags) =>
      prevTags.some((tag) => tag.id === id)
        ? prevTags.filter((tag) => tag.id !== id)
        : [...prevTags, { id, value: prevTags.length === 0 ? 100 : 0 }]
    );
  };

  const getVideos = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var raw = JSON.stringify({
        prerec_cou_id: currentCourseId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${base_url}/prerec/prerecordedcourse/get/content/pre/rec/cou/by/id`,
        requestOptions
      );
      const result = await response.json();
      if (result.status === 1) {
        setVideoData(result.sen_data.vedios);
      } else if (result.status == "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTags = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(`${base_url}/quiz/prerec_topic/get/pre/rec/tag`, requestOptions)
      const result = await response.json();
          if (result.status == 1) {
            setTagsData(result.topics);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
          }
    } catch (error) {
      console.log(error);
    }
  };

  const addQuiz = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      const topicRatioObject = selectedTags.reduce((result, tag) => {
        result[tag.id] = tag.value;
        return result;
      }, {});

      var raw = JSON.stringify({
        quiz_name: quizName,
        pass_pers: slider4,
        number_of_question: quizSpec,
        time_per_question: quizTime,
        quiz_rules: [quizRules],
        topic_ratio: topicRatioObject,
        prerec_videoname: selectedValue,
        prerec_videoid: selectedVideoId,
        prerec_couid: currentCourseId,
        prerec_couname: currentCourseName,
        marks_per_question: marksPerQues,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

     const response = await fetch(
        `${base_url}/quiz/prerec_quiz/create/quiz/in/prereccou`,
        requestOptions
      )
      const result = await response.json();
          if (result.status == 1) {
            toast.success(result.msg);
            setQuizName("");
            setQuizSpec("");
            setQuizTime("");
            setQuizRules("");
            setSelectedTags([]);
            setSelectedValue("");
            setSelectedVideoId("");
            currentCourseId("");
            currentCourseName("");
            setMarksPerQues("");  
            setProgress(7);
          }
          else if (result.status === 'VAL_ERR') { 
            toast.error(result.Backend_Error) 
          } 
           else if (result.status == "CUSTOM_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
          }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getVideos();
    getTags();
  }, []);
  console.log(selectedTags);

  return (
    <>
      {progress === 1 ? (
        <>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-10 h-100 mx-auto">
                <div className="h-100 d-flex h-100 flex-column justify-content-around">
                  <div>
                    <h4 color="#434343">Create New Quiz</h4>
                    <h6 style={{ color: "#787878", fontSize: "0.7rem" }}>
                      <span style={{ color: "#3D70F5" }}>{progress}</span>/7
                      Steps
                    </h6>
                    <div>
                      <ProgressBar
                        completed={progress}
                        bgColor="#3D70F5"
                        height="7px"
                        labelAlignment="center"
                        isLabelVisible={false}
                        labelColor="#e8090"
                        labelSize="30px"
                        animateOnRender
                        maxCompleted={6}
                      />
                    </div>
                  </div>

                  <div className="card p-2 px-5">
                    <p>Select Videos</p>

                    <div
                      class="input-group w-100 pe-3"
                      style={{ backgroundColor: "#F1F1F1 !important" }}
                    >
                      <span
                        class="input-group-text border-0"
                        id="basic-addon1"
                        style={{ background: "#F1F1F1" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M12.9167 11.6667H12.2583L12.025 11.4417C12.8699 10.4617 13.3343 9.21058 13.3333 7.91667C13.3333 6.84535 13.0157 5.7981 12.4205 4.90733C11.8253 4.01656 10.9793 3.3223 9.98954 2.91232C8.99977 2.50235 7.91066 2.39508 6.85993 2.60408C5.8092 2.81309 4.84404 3.32897 4.08651 4.08651C3.32897 4.84404 2.81309 5.8092 2.60408 6.85993C2.39508 7.91066 2.50235 8.99977 2.91232 9.98954C3.3223 10.9793 4.01656 11.8253 4.90733 12.4205C5.7981 13.0157 6.84535 13.3333 7.91667 13.3333C9.25834 13.3333 10.4917 12.8417 11.4417 12.025L11.6667 12.2583V12.9167L15.8333 17.075L17.075 15.8333L12.9167 11.6667ZM7.91667 11.6667C5.84167 11.6667 4.16667 9.99167 4.16667 7.91667C4.16667 5.84167 5.84167 4.16667 7.91667 4.16667C9.99167 4.16667 11.6667 5.84167 11.6667 7.91667C11.6667 9.99167 9.99167 11.6667 7.91667 11.6667Z"
                            fill="#9E9E9E"
                          />
                        </svg>
                      </span>
                      <input
                        style={{ backgroundColor: "#F1F1F1" }}
                        type="text"
                        class="form-control border-0"
                        placeholder="Search Videos"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>

                    <div
                      className="mt-3 example"
                      style={{ height: "130px", overflowY: "auto" }}
                    >
                      {videoData <= 0 ? (
                        <>
                          <p className="text-center mt-3">
                            pls upload video first!
                          </p>
                        </>
                      ) : (
                        <>
                          {videoData.map((data, index) => (
                            <div
                              className="d-flex align-items-center gap-2 mb-2"
                              key={index}
                            >
                              <input
                                type="radio"
                                name="selectVideo"
                                style={{ cursor: "pointer" }}
                                value={data.title}
                                onChange={() =>
                                  handleRadioChange(data.title, data.id)
                                }
                                checked={selectedValue === data.title}
                              />
                              <span>{data.title}</span>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="w-100 mt-4">
                    <button
                      className="w-100 py-2 btn text-white"
                      onClick={() => {
                        if (selectedValue <= 0) {
                          toast.error("pls select video");
                        } else {
                          setProgress(2);
                        }
                      }}
                      style={{ backgroundColor: "#3D70F5" }}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {progress === 2 ? (
        <>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-10 h-100 mx-auto">
                <div className="h-100 d-flex h-100 flex-column justify-content-around">
                  <div>
                    <h4 color="#434343">Create New Quiz</h4>
                    <h6 style={{ color: "#787878", fontSize: "0.7rem" }}>
                      <span style={{ color: "#3D70F5" }}>{progress}</span>/7
                      Steps
                    </h6>
                    <div>
                      <ProgressBar
                        completed={progress}
                        bgColor="#3D70F5"
                        height="7px"
                        labelAlignment="center"
                        isLabelVisible={false}
                        labelColor="#e8090"
                        labelSize="30px"
                        animateOnRender
                        maxCompleted={6}
                      />
                    </div>
                  </div>

                  <div className="card p-2 px-5">
                    <p>Select Tags</p>

                    <div
                      class="input-group w-100 pe-3"
                      style={{ backgroundColor: "#F1F1F1 !important" }}
                    >
                      <span
                        class="input-group-text border-0"
                        id="basic-addon1"
                        style={{ background: "#F1F1F1" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M12.9167 11.6667H12.2583L12.025 11.4417C12.8699 10.4617 13.3343 9.21058 13.3333 7.91667C13.3333 6.84535 13.0157 5.7981 12.4205 4.90733C11.8253 4.01656 10.9793 3.3223 9.98954 2.91232C8.99977 2.50235 7.91066 2.39508 6.85993 2.60408C5.8092 2.81309 4.84404 3.32897 4.08651 4.08651C3.32897 4.84404 2.81309 5.8092 2.60408 6.85993C2.39508 7.91066 2.50235 8.99977 2.91232 9.98954C3.3223 10.9793 4.01656 11.8253 4.90733 12.4205C5.7981 13.0157 6.84535 13.3333 7.91667 13.3333C9.25834 13.3333 10.4917 12.8417 11.4417 12.025L11.6667 12.2583V12.9167L15.8333 17.075L17.075 15.8333L12.9167 11.6667ZM7.91667 11.6667C5.84167 11.6667 4.16667 9.99167 4.16667 7.91667C4.16667 5.84167 5.84167 4.16667 7.91667 4.16667C9.99167 4.16667 11.6667 5.84167 11.6667 7.91667C11.6667 9.99167 9.99167 11.6667 7.91667 11.6667Z"
                            fill="#9E9E9E"
                          />
                        </svg>
                      </span>
                      <input
                        style={{ backgroundColor: "#F1F1F1" }}
                        type="text"
                        class="form-control border-0"
                        placeholder="Search Tags"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>

                    <div
                      className="mt-3 example"
                      style={{ height: "130px", overflowY: "auto" }}
                    >
                      {tagsData <= 0 ? (
                        <>
                          <p className="text-center mt-3">
                            pls add tag first !
                          </p>
                        </>
                      ) : (
                        <>
                          {tagsData.map((data, index) => (
                            <div
                              className="d-flex align-items-center gap-2 mb-2"
                              key={index}
                            >
                              <input
                                type="checkbox"
                                name="selectVideo"
                                style={{ cursor: "pointer" }}
                                onChange={() =>
                                  handleCheckboxChange(
                                    data._id,
                                    data.topic_name
                                  )
                                }
                                checked={selectedTagsIds.includes(data._id)}
                              />

                              <span>{data.topic_name}</span>
                            </div>
                          ))}
                          {/* <p>{selectedTagsIds + " " + selectedTagsNames}</p> */}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="w-100 mt-4 d-flex align-items-center justify-content-between">
                    <div
                      style={{ color: "#6B6B6B", cursor: "pointer" }}
                      onClick={() => setProgress(1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M15.0001 8.25006V9.75006H6.00012L10.1251 13.8751L9.06012 14.9401L3.12012 9.00006L9.06012 3.06006L10.1251 4.12506L6.00012 8.25006H15.0001Z"
                          fill="#6B6B6B"
                        />
                      </svg>
                      Back
                    </div>
                    <button
                      className="py-2 btn text-white"
                      onClick={() => {
                        if (selectedTagsIds.length <= 0) {
                          toast.error("pls select tags");
                        } else {
                          setProgress(3);
                        }
                      }}
                      style={{ backgroundColor: "#3D70F5" }}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {progress === 3 ? (
        <>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-10 h-100 mx-auto">
                <div className="h-100 d-flex h-100 flex-column justify-content-around">
                  <div>
                    <h4 color="#434343">Create New Quiz</h4>
                    <h6 style={{ color: "#787878", fontSize: "0.7rem" }}>
                      <span style={{ color: "#3D70F5" }}>{progress}</span>/7
                      Steps
                    </h6>
                    <div>
                      <ProgressBar
                        completed={progress}
                        bgColor="#3D70F5"
                        height="7px"
                        labelAlignment="center"
                        isLabelVisible={false}
                        labelColor="#e8090"
                        labelSize="30px"
                        animateOnRender
                        maxCompleted={6}
                      />
                    </div>
                  </div>

                  <div className="card p-2 px-5">
                    <p className="mb-0">Select Question Composition</p>

                    <div
                      className="mt-3 example"
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                    >
                      {/* <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="mb-0">Current Affairs</p>
                          <p className="mb-0">{slider}%</p>
                        </div>
                        <Slider
                          max={100}
                          className="w-100"
                          style={{ color: "#2188E7" }}
                          onChange={(e) => setSlider(e.target.value)}
                          defaultValue={slider}
                        />
                      </div>
                      <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="mb-0">Logical Reasoning</p>
                          <p className="mb-0">{slider1}%</p>
                        </div>
                        <Slider
                          max={100}
                          className="w-100"
                          style={{ color: "#C922E4" }}
                          onChange={(e) => setSlider1(e.target.value)}
                          defaultValue={slider1}
                        />
                      </div>
                      <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="mb-0">Basic Computers</p>
                          <p className="mb-0">{slider2}%</p>
                        </div>
                        <Slider
                          max={100}
                          className="w-100"
                          style={{ color: "#CC1313" }}
                          onChange={(e) => setSlider2(e.target.value)}
                          defaultValue={slider2}
                        />
                      </div>
                      <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="mb-0">Basic Science</p>
                          <p className="mb-0">{slider3}%</p>
                        </div>
                        <Slider
                          max={100}
                          className="w-100"
                          style={{ color: "#FF8E09" }}
                          onChange={(e) => setSlider3(e.target.value)}
                          defaultValue={slider3}
                        />
                      </div> */}

                      {/* {selectedTags.length > 0 && (
       <> */}
                      {selectedTags.map((tag, value) => (
                        <div className="px-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0">{tag.name}</p>
                            <p className="mb-0">{parseInt(tag.value)}%</p>
                          </div>
                          <Slider
                            max={100}
                            key={tag.id}
                            id={tag.id}
                            className="w-100"
                            style={{ color: colors[value % 4] }}
                            onChange={(e) =>
                              handleChangeName(tag.id, e.target.value)
                            }
                            value={tag.value}
                            total={selectedTags.reduce(
                              (total, Value) => total + Value.value,
                              0
                            )}
                          />
                        </div>
                      ))}
                      {/* </ul> */}
                      {/* </>
      )} */}
                    </div>
                  </div>

                  <div className="w-100 mt-4 d-flex align-items-center justify-content-between">
                    <div
                      style={{ color: "#6B6B6B", cursor: "pointer" }}
                      onClick={() => setProgress(2)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M15.0001 8.25006V9.75006H6.00012L10.1251 13.8751L9.06012 14.9401L3.12012 9.00006L9.06012 3.06006L10.1251 4.12506L6.00012 8.25006H15.0001Z"
                          fill="#6B6B6B"
                        />
                      </svg>
                      Back
                    </div>
                    <button
                      className="py-2 btn text-white"
                      onClick={() => setProgress(4)}
                      style={{ backgroundColor: "#3D70F5" }}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {progress === 4 ? (
        <>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-10 h-100 mx-auto">
                <div className="h-100 d-flex h-100 flex-column justify-content-around">
                  <div>
                    <h4 color="#434343">Create New Quiz</h4>
                    <h6 style={{ color: "#787878", fontSize: "0.7rem" }}>
                      <span style={{ color: "#3D70F5" }}>{progress}</span>/7
                      Steps
                    </h6>
                    <div>
                      <ProgressBar
                        completed={progress}
                        bgColor="#3D70F5"
                        height="7px"
                        labelAlignment="center"
                        isLabelVisible={false}
                        labelColor="#e8090"
                        labelSize="30px"
                        animateOnRender
                        maxCompleted={6}
                      />
                    </div>
                  </div>

                  <div className="card p-2 px-5">
                    <p className="mb-0">Quiz Rules</p>
                    <div className="mt-3 py-3 example">
                      <div>
                        <Form.Control
                          className="example outline-0"
                          as="textarea"
                          style={{ height: "10px", background: "#F5F5F5" }}
                          placeholder="Start Typing the Rules"
                          onChange={(e) => setQuizRules(e.target.value)}
                        />
                      </div>

                      <input
                        type="number"
                        className="w-100 py-2 px-3 border-0 mt-3"
                        style={{
                          background: "#F5F5F5",
                          outline: "none",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => setMarksPerQues(e.target.value)}
                        placeholder="Enter Marks Per Question"
                      />

                      <div className="mt-3 d-flex align-items-center gap-3">
                        <input
                          type="number"
                          className="w-100 py-2 px-3 border-0"
                          style={{
                            background: "#F5F5F5",
                            outline: "none",
                            borderRadius: "5px",
                          }}
                          onChange={(e) => setQuizTime(e.target.value)}
                          placeholder="Enter Time Per Question"
                        />
                        <button
                          className="btn"
                          style={{ background: "#F5F5F5", outline: "none" }}
                        >
                          Seconds
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="w-100 mt-4 d-flex align-items-center justify-content-between">
                    <div
                      style={{ color: "#6B6B6B", cursor: "pointer" }}
                      onClick={() => setProgress(3)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M15.0001 8.25006V9.75006H6.00012L10.1251 13.8751L9.06012 14.9401L3.12012 9.00006L9.06012 3.06006L10.1251 4.12506L6.00012 8.25006H15.0001Z"
                          fill="#6B6B6B"
                        />
                      </svg>
                      Back
                    </div>
                    <button
                      className="py-2 btn text-white"
                      onClick={() => {
                        if (quizRules <= 0) {
                          toast.error("pls enter quiz rules");
                        } else if (marksPerQues <= 0) {
                          toast.error("pls enter marks per question");
                        } else if (quizTime <= 0) {
                          toast.error("pls enter quiz time");
                        } else {
                          setProgress(5);
                        }
                      }}
                      style={{ backgroundColor: "#3D70F5" }}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {progress === 5 ? (
        <>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-10 h-100 mx-auto">
                <div className="h-100 d-flex h-100 flex-column justify-content-around">
                  <div>
                    <h4 color="#434343">Create New Quiz</h4>
                    <h6 style={{ color: "#787878", fontSize: "0.7rem" }}>
                      <span style={{ color: "#3D70F5" }}>{progress}</span>/7
                      Steps
                    </h6>
                    <div>
                      <ProgressBar
                        completed={progress}
                        bgColor="#3D70F5"
                        height="7px"
                        labelAlignment="center"
                        isLabelVisible={false}
                        labelColor="#e8090"
                        labelSize="30px"
                        animateOnRender
                        maxCompleted={6}
                      />
                    </div>
                  </div>

                  <div className="card p-2 px-5">
                    <p className="mb-1 mt-1">Quiz Name</p>
                    <input
                      type="text"
                      className="w-100 py-2 px-3 border-0"
                      style={{
                        background: "#F5F5F5",
                        outline: "none",
                        borderRadius: "5px",
                      }}
                      onChange={(e) => setQuizName(e.target.value)}
                      placeholder="Enter Quiz Name"
                    />

                    <div className="mt-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="mb-0">Passing Percentage</p>
                        <p className="mb-0" style={{ color: "#3D70F5" }}>
                          {slider4}%
                        </p>
                      </div>
                      <Slider
                        max={100}
                        className="w-100"
                        style={{ color: "#2C62EE" }}
                        onChange={(e) => setSlider4(e.target.value)}
                        defaultValue={slider4}
                      />
                    </div>

                    <div className="">
                      {/* <div className="d-flex align-items-center justify-content-between">
                        <p className="mb-0">Quiz Schedule</p>
                      </div> */}

                      {/* <div className="d-flex align-items-center overflow-hidden ">
                        <DatePicker
                          className="border-0 w-75 datep"
                          showIcon
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                        <input
                          className="border-0 p-1 w-50   datep"
                          type="time"
                          value={Times}
                          onChange={(e) => {
                            setTimes(e.target.value);
                          }}
                        />
                      </div> */}

                      <p className="mb-1">Quiz Specifications</p>
                      <input
                        type="number"
                        className="w-100 py-2 px-3 border-0"
                        style={{
                          background: "#F5F5F5",
                          outline: "none",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => setQuizSpec(e.target.value)}
                        placeholder="Number of Questions"
                      />
                    </div>
                  </div>

                  <div className="w-100 mt-4 d-flex align-items-center justify-content-between">
                    <div
                      style={{ color: "#6B6B6B", cursor: "pointer" }}
                      onClick={() => {
                        setProgress(4);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M15.0001 8.25006V9.75006H6.00012L10.1251 13.8751L9.06012 14.9401L3.12012 9.00006L9.06012 3.06006L10.1251 4.12506L6.00012 8.25006H15.0001Z"
                          fill="#6B6B6B"
                        />
                      </svg>
                      Back
                    </div>
                    <button
                      className="py-2 btn text-white"
                      onClick={() => {
                        if (quizName <= 0) {
                          toast.error("pls enter quiz name");
                        } else if (slider4 == 0) {
                          toast.error("pls select passing percentage");
                        } else if (Times <= 0) {
                          toast.error("pls select time");
                        } else if (startDate <= 0) {
                          toast.error("pls select date");
                        } else if (quizSpec <= 0) {
                          toast.error("pls enter number of questions");
                        } else {
                          setProgress(6);
                        }
                      }}
                      style={{ backgroundColor: "#3D70F5" }}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {progress === 6 ? (
        <>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-10 h-100 mx-auto">
                <div className="h-100 d-flex h-100 flex-column justify-content-around">
                  <div>
                    <h4 color="#434343">Create New Quiz</h4>
                    <h6 style={{ color: "#787878", fontSize: "0.7rem" }}>
                      <span style={{ color: "#3D70F5" }}>{progress}</span>/7
                      Steps
                    </h6>
                    <div>
                      <ProgressBar
                        completed={progress}
                        bgColor="#3D70F5"
                        height="7px"
                        labelAlignment="center"
                        isLabelVisible={false}
                        labelColor="#e8090"
                        labelSize="30px"
                        animateOnRender
                        maxCompleted={6}
                      />
                    </div>
                  </div>

                  <div className="card p-2 px-5">
                    <p className="mb-0">Preview</p>

                    <div
                      className="mt-3 py-3 example w-100"
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      {/* {previewData.map((data, value) => (
                        <> */}
                      <p style={{ color: "#434343" }}>
                        Course :{" "}
                        <span style={{ fontWeight: 800 }}>
                          {/* {data.course} */}
                          {currentCourseName}
                        </span>
                      </p>
                      <p style={{ color: "#434343" }}>
                        Number of Questions :{" "}
                        <span style={{ fontWeight: 800 }}>{quizSpec}</span>
                      </p>
                      <div className="mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="mb-0" style={{ color: "#434343" }}>
                            Passing Percentage
                          </p>
                          <p className="mb-0" style={{ color: "#3D70F5" }}>
                            {slider4}%
                          </p>
                        </div>
                        <Slider
                          max={100}
                          className="w-100"
                          disabled
                          style={{ color: "#2C62EE" }}
                          onChange={(e) => setSlider4(e.target.value)}
                          defaultValue={slider4}
                        />
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-1">Quiz Schedule</p>
                          </div> */}

                      {/* <div
                            style={{ color: "#434343" }}
                            className="d-flex gap-3 align-items-center"
                          >
                            <p>Date : {startDate.toLocaleDateString()}</p>
                            <p className=""> Time : {Times}</p>
                          </div> */}

                      <div className="d-flex align-items-center justify-content-between">
                        <p className="mb-1">Tags Composition</p>
                      </div>
                      {selectedTags.map((tag, value) => (
                        <div className="px-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0">{tag.name}</p>
                            <p className="mb-0" style={{ color: "#3D70F5" }}>
                              {parseInt(tag.value)}%
                            </p>
                          </div>
                          <Slider
                            max={100}
                            key={tag.id}
                            disabled
                            id={tag.id}
                            className="w-100"
                            style={{ color: colors[value % 4] }}
                            // onChange={(e) => handleChangeName(tag.id, e.target.value)}
                            value={tag.value}
                            total={selectedTags.reduce(
                              (total, Value) => total + Value.value,
                              0
                            )}
                          />
                        </div>
                      ))}

                      <div>
                        <p className="mb-0" style={{ color: "#434343" }}>
                          Rules
                        </p>
                        <p style={{ fontSize: "0.7rem" }}>{quizRules}</p>
                      </div>

                      <div>
                        <p
                          className="ps-2 p-1"
                          style={{
                            background: "#F5F5F5",
                            color: "#767676",
                            fontSize: "0.9rem",
                            borderRadius: "3px",
                          }}
                        >
                          Times Per Questions : {quizTime}
                        </p>
                      </div>
                      {/* </>
                      ))} */}
                    </div>
                  </div>

                  <div className="w-100 mt-4 d-flex align-items-center justify-content-between">
                    <div
                      style={{ color: "#6B6B6B", cursor: "pointer" }}
                      onClick={() => setProgress(5)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M15.0001 8.25006V9.75006H6.00012L10.1251 13.8751L9.06012 14.9401L3.12012 9.00006L9.06012 3.06006L10.1251 4.12506L6.00012 8.25006H15.0001Z"
                          fill="#6B6B6B"
                        />
                      </svg>
                      Back
                    </div>
                    <button
                      className="py-2 btn text-white"
                      onClick={() => {
                        addQuiz();
                      }}
                      style={{ backgroundColor: "#3D70F5" }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {progress === 7 ? (
        <>
          <div className="mt-4 mx-auto">
            <h4 className="text-center">Quiz Created Successfully</h4>
            <p className="text-center">
              New Quiz added in {currentCourseName} <br />
              Succesfully with the following Tags :{" "}
              {tagsData.map((data, index) => (
                <span className="mb-2" key={index}>
                  {data.topic_name}
                  {index !== tagsData.length - 1 && ", "}
                </span>
              ))}{" "}
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <button
                onClick={() => handleClose2()}
                className="py-2 mx-auto text-center btn text-white"
                style={{ backgroundColor: "#3D70F5" }}
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AddQuiz;
