import React, { useState, useEffect, useContext } from "react";
import { InfinitySpin } from "react-loader-spinner";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { base_url } from "../env";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Typography } from "@mui/material";

const AccountsMain = () => {
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);

  const getCourses = async () => {
    setIsLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${base_url}/auth/admin/accounts/open/accounts/tab`,
        requestOptions
      );
      const result = await response.json();
      if (result.status == 1) {
        setCardData(result.courses);

        let totalU = 0;
        let totalA = 0;
        console.log(result.courses);
        result.courses.forEach((e) => {
          totalU += e.total_users;

          if (e.total_amount !== null) {
            totalA += Number(e.total_amount);
          }
        });
        console.log("tot", totalU);
        console.log("tota", totalA);
        setTotalUsers(totalU);
        setTotalRevenue(totalA);
      } else if (result.status == "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
      } else {
        toast.error(result.bakend_error);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <>
      <div
        className="container-fluid"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <div className="row h-100">
          <div className="col-2 h-100 p-0">
            <Sidebar />
          </div>

          {/* Main Content */}
          <div className="col-10 h-100 p-0">
            <div style={{ height: "10%", background: "#2C62EE" }}>
              <Header />
            </div>

            {/* mid part */}

            <div
              style={{
                height: "20%",
                margin: "5px",
                borderBottom: "2px solid black",
              }}
              className=" py-3  d-flex flex-row justify-content-around"
            >
              <Card
                style={{
                  width: "18rem",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#2C62EF",
                }}
              >
                <Card.Body>
                  <Card.Title>Total Revenue</Card.Title>
                  <Card.Subtitle style={{ color: "wheat" }} className="mb-2 ">
                    ₹ {totalRevenue}
                  </Card.Subtitle>
                </Card.Body>
              </Card>

              <Card
                style={{
                  width: "18rem",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#2C62EF",
                }}
              >
                <Card.Body>
                  <Card.Title>Total Enrollments</Card.Title>
                  <Card.Subtitle style={{ color: "wheat" }} className="mb-2 ">
                    {totalUsers}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>

            {/* bottom */}
            <div
              style={{ height: "70%", overflowY: "auto" }}
              className="p-3 example row row-gap-4"
            >
              {cardData <= 0 ? (
                <>
                  {isLoading == true ? (
                    <>
                      <div className="col-6 h-100 text-center w-100 mx-auto">
                        <InfinitySpin width="300" color="#3D70F5" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mt-5">
                        <h5 className="text-center mt-5">
                          No Courses Found , Please Add Courses !
                        </h5>
                      </div>
                    </>
                  )}
                </>
              ) : (
                cardData?.map((data, value) => (
                  <div
                    className="col-4"
                    key={value}
                    style={{ height: "fit-content" }}
                  >
                    <div className="card h-100 ">
                      <img
                        src={""}
                        alt="something"
                        className="w-100"
                        style={{
                          objectFit: "cover",
                          height: "200px",
                          objectPosition: "center center",
                        }}
                      />
                      <div className="px-2 mt-1">
                        <div
                          className="border text-center rounded"
                          style={{ height: "80px" }}
                        >
                          <Typography
                            className="mb-0"
                            noWrap
                            style={{ fontSize: "1.3rem", fontWeight: 700 }}
                          >
                            Department : {data.cou_name}
                          </Typography>
                          <p
                            className="mb-0"
                            style={{ fontSize: "1rem", fontWeight: 600 }}
                          >
                            Amount : ₹{" "}
                            {data.total_amount === null ? 0 : data.total_amount}
                          </p>
                          <p
                            className="mb-0"
                            style={{ fontSize: "0.9rem", fontWeight: 500 }}
                          >
                            Users : {data.total_users}
                          </p>
                        </div>

                        <div className="my-2">
                          <button
                            onClick={() => {
                              Navigate(
                                `ViewCourse/${data.id}/${data.cou_name}/${data.total_amount}/${data.total_users}`
                              );
                            }}
                            className="btn py-1 w-100 text-white"
                            style={{ background: "#2C62EE" }}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountsMain;
