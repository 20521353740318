import React, { useContext, useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { token, base_url, PREREC_BASE_URL, IMAGE_URL } from "../env";
// import ReactPlayer from "react-player/lazy";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Context } from "../..";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TfiControlForward } from "react-icons/tfi";
import shaka from "shaka-player";
import { InfinitySpin } from "react-loader-spinner";
import ReactPlayer from "react-player";

const Content = ({ currentCourseId }) => {
  const { refreshVideoPage, setRefreshVideoPage } = useContext(Context);

  const Navigate = useNavigate();
  const videoRef = useRef(null);
  const [show, setShow] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState();
  const [selectedVideoUrl, setSelectedVideoUrl] = useState();
  const [selectedVideoTitle, setSelectedVideoTitle] = useState();
  const [showVideos, setShowVideos] = useState("");
  const [videoToken, setVideoToken] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (videoId, videoTitle) => {
    setSelectedVideoId(videoId);
    setSelectedVideoTitle(videoTitle);
    setShow(true);
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (videoId, videoUrl) => {
    setSelectedVideoId(videoId);
    setSelectedVideoUrl(videoUrl);
    renderVideos(videoId);

    setShow1(true);
  };

  const { videoData, setVideoData } = useContext(Context);
  const [studyMaterial, setStudyMaterial] = useState([]);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = async (videoId) => {
    getStudyMaterial(videoId);

    setShow3(true);
  };

  const getVideos = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("brainbucks_token")} `
    );

    const raw = JSON.stringify({
      course_id: currentCourseId,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${PREREC_BASE_URL}/client/view/videos/of/course`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          setVideoData(result.sen_data.vedios);
          setRefreshVideoPage(false);
        } else if (result.status === "TOKEN_ERR") {
          localStorage.removeItem("brainbucks_token");
          localStorage.removeItem("username");
          Navigate("/");
        }
      })
      .catch((error) => console.error(error));
  };

  const getStudyMaterial = async (videoId) => {
    try {
      const response = await fetch(
        `${PREREC_BASE_URL}/client/studymaterials/get/video/study/materials`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("brainbucks_token")}`,
          },
          body: JSON.stringify({
            video_id: videoId,
          }),
        }
      );

      const result = await response.json();

      if (result.status == 1) {
        setStudyMaterial(result.study_materials);
      } else if (result.status === "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteVideo = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      const raw = JSON.stringify({
        video_id: selectedVideoId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${base_url}/prerec/prerecordedcourse/delete/video/in/pre/rec`,
        requestOptions
      );

      const result = await response.json();

      if (result.status === 1) {
        toast.success(result.msg);
        getVideos();
      } else if (result.status === "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
      }
    } catch (error) {
      console.error("Error deleting video:", error);
    }
  };

  const [anchorElArray, setAnchorElArray] = useState([]);

  const [openArray, setOpenArray] = useState([]);

  const handleClick = (event, index) => {
    setAnchorElArray([
      ...anchorElArray.slice(0, index),
      event.currentTarget,
      ...anchorElArray.slice(index + 1),
    ]);
    setOpenArray([
      ...openArray.slice(0, index),
      true,
      ...openArray.slice(index + 1),
    ]);
  };

  const handleClose2 = (index) => {
    setAnchorElArray([
      ...anchorElArray.slice(0, index),
      null,
      ...anchorElArray.slice(index + 1),
    ]);
    setOpenArray([
      ...openArray.slice(0, index),
      false,
      ...openArray.slice(index + 1),
    ]);
  };

  const renderStudyMaterials = () => {
    return studyMaterial.map((data) => (
      <div
        className="card d-flex flex-row px-3 py-2 mt-2 mb-3 justify-content-between align-items-center"
        key={data._id}
      >
        <p className="mb-0">{data.title}</p>
        <TfiControlForward
          style={{ cursor: "pointer" }}
          onClick={() => {
            const pdfUrl = `${IMAGE_URL}${data?.pdf_url}`;

            if (pdfUrl) {
              window.open(pdfUrl, "_blank");
            }
          }}
        />
      </div>
    ));
  };

  // api to render videos
  const renderVideos = async (videoId) => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("brainbucks_token")}`
    );

    const raw = JSON.stringify({
      video_id: videoId,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${PREREC_BASE_URL}/client/start/videos/of/course`,
        requestOptions
      );
      const result = await response.json();

      if (result.status === 1) {
        setShowVideos(result?.url);
        setVideoToken(result?.token);
      } else if (result.status === "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
      } else {
        toast(result?.Backend_Error);
        setShowVideos(null);
      }
    } catch (error) {
      console.error(error);
      toast("An error occurred while fetching the video.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getVideos();
  }, [refreshVideoPage]);

  // useEffect(() => {
  //   if (showVideos && videoToken) {
  //     const initPlayer = async () => {
  //       const videoElement = videoRef.current;
  //       const player = new shaka.Player(videoElement);
  //       shaka.polyfill.installAll();

  //       const protection = {
  //         drm: {
  //           servers: {
  //             "com.widevine.alpha":
  //               "https://drm-widevine-licensing.axprod.net/AcquireLicense",
  //           },
  //         },
  //       };

  //       player.configure(protection);

  //       player
  //         .getNetworkingEngine()
  //         .registerRequestFilter(function (type, request) {
  //           if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
  //             request.headers["X-AxDRM-Message"] = `${videoToken}`;
  //           }
  //         });

  //       try {
  //         await player.load(`${showVideos}`);
  //       } catch (error) {
  //         console.error("Error loading video:", error);
  //       }
  //     };

  //     initPlayer();

  //     return () => {
  //       if (videoRef.current && videoRef.current.player) {
  //         videoRef.current.player.destroy();
  //       }
  //     };
  //   }
  // }, [showVideos, videoToken]);

  

  return (
    <>
      {videoData.length > 0 ? (
        <>
          {videoData
            ?.slice()
            .reverse()
            .map((data, value) => (
              <React.Fragment key={value}>
                <div className="d-flex card flex-row align-items-center justify-content-between p-1 px-2 mt-2 mb-3">
                  <div className="d-flex align-items-center gap-2">
                    <span>{data.title}</span>
                  </div>

                  <Tooltip title="Actions">
                    <IconButton
                      onClick={(event) => handleClick(event, value)} // Pass the index to handleClick
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={
                        openArray[value] ? "account-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openArray[value] ? "true" : undefined}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    anchorEl={anchorElArray[value]}
                    id="account-menu"
                    open={openArray[value]}
                    onClose={() => handleClose2(value)} // Pass the index to handleClose2
                    onClick={() => handleClose2(value)}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                     onClick={() => {
                      if (data.is_upload === 1) {
                        handleClose2();
                        handleShow1(data._id);
                      } else {
                        data.is_upload == 0
                          ? toast("Video upload failed.")
                          : data.is_upload == 2
                          ? toast("Video is encoding.")
                          : data.is_upload == 3
                          ? toast("Video is uploading.")
                          : toast("Unknown video status.");
                      }
                    }}
                    >
                      <ListItemIcon>
                        <PlayArrowIcon fontSize="small" />
                      </ListItemIcon>
                      Play Video
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose2();
                        handleShow3(data?._id);
                      }}
                    >
                      <ListItemIcon>
                        <LibraryBooksIcon fontSize="small" />
                      </ListItemIcon>
                      Study Materials
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose2();
                        handleShow(data?._id, data?.title);
                      }}
                    >
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      Delete Video
                    </MenuItem>
                  </Menu>
                </div>

                {/* play video modal started  */}
                <Modal show={show1} onHide={handleClose1} centered>
                  <Modal.Header closeButton className="border-0"></Modal.Header>
                  <Modal.Body className="mt-0 pt-0 pb-4">
                    <div className="container">

                     {/* code for shaka player for drm video we can use whenever drm availbale */}

                      {/* <div className="row ">
                        {isLoading || showVideos === null ? (
                          <div className="col d-flex justify-content-center align-items-center">
                            <InfinitySpin
                              width="300"
                              style={{}}
                              color="#3D70F5"
                              className="ms-2"
                            />
                          </div>
                        ) : (
                          <div className="col-12">
                        

                            <video
                              ref={videoRef}
                              controls
                              height="250"
                              width="440px"
                            ></video>
                          </div>
                        )}
                      </div> */}

<div className="row ">
  <div className="col-12">
  <ReactPlayer
                          url={showVideos}
                          controls={true}
                          className="w-100"
                          height="250px"
                          // style={{ height: "100px" }}
                        />
  </div>
  </div>

                    </div>
                  </Modal.Body>
                </Modal>
                {/* play video modal end  */}

                {/* get study material modal started  */}
                <Modal show={show3} onHide={handleClose3} centered>
                  <Modal.Header closeButton className="border-0"></Modal.Header>
                  <Modal.Body className="mt-0 pt-0 pb-4">
                    <div className="container">
                      <div className="row">
                        <div
                          className="col-12 example"
                          style={{ height: "200px", overflowY: "auto" }}
                        >
                          {studyMaterial.length > 0 ? (
                            renderStudyMaterials()
                          ) : (
                            <div className="d-flex align-items-center justify-content-center h-100">
                              <h3 className="text-center">
                                No Study Material Found
                              </h3>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                {/* get study material modal end  */}

                {/* delete video modal started  */}
                <Modal
                  show={show}
                  onHide={handleClose}
                  centered
                  style={{ backdropFilter: "blur(7px)" }}
                >
                  <Modal.Header closeButton className="border-0"></Modal.Header>
                  <Modal.Body className="mt-0 pt-0 pb-4">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex flex-column gap-4">
                            <h5
                              className="text-center"
                              style={{ fontWeight: 700 }}
                            >
                              Remove Content
                            </h5>
                            <p className="text-center">{selectedVideoTitle}</p>
                            <div className="d-flex align-items-center gap-3 ">
                              <button
                                className="flex-grow-1 w-50 py-1 bg-transparent"
                                onClick={handleClose}
                                style={{
                                  border: "1px solid #F93333",
                                  borderRadius: "5px",
                                  color: "#f93333",
                                }}
                              >
                                Keep
                              </button>
                              <button
                                className="flex-grow-1 w-50 py-1 bg-transparent"
                                onClick={() => {
                                  deleteVideo();
                                  handleClose();
                                }}
                                style={{
                                  border: "1px solid #4AAD1B",
                                  borderRadius: "5px",
                                  color: "#4AAD1B",
                                }}
                              >
                                Yes, remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                {/* delete video modal end  */}
              </React.Fragment>
            ))}
        </>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-center h-100">
            <h3 className="text-center">No Video's Found</h3>
          </div>
        </>
      )}

      <Toaster position="top-right" />
    </>
  );
};

export default Content;
