import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { base_url, token } from "../env";
import { InfinitySpin } from "react-loader-spinner";
import { Context } from "../../index.js";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const BannerMain = () => {
  const Navigate = useNavigate();
  const { tabRefresh, setTabRefresh } = useContext(Context);
  console.log(tabRefresh);
  const [isLoading, setIsLoading] = useState(false);

  // states for handle
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [file, setFile] = useState(null);
  const [array, setArray] = useState([]);
  const [deleteId, setDeleteId] = useState(0);

  // creating comman header
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    `Bearer ${localStorage.getItem("brainbucks_token")}`
  );

  // for getting images
  const fetchBlobImages = async () => {
    setIsLoading(true);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let res = await fetch(`${base_url}/auth/banner/get/banner`, requestOptions);
    let data = await res.json();
    setArray(data.banner);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBlobImages();
  }, [tabRefresh]);

  // for uploading image
  const handleFileUpload = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      const formData = new FormData();
      formData.append("banner", file);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };

      const response = await fetch(
        `${base_url}/auth/banner/add/banner`,
        requestOptions
      );
      const result = await response.json();
      if (result.status == 1) {
        setTabRefresh(!tabRefresh);
        setFile(null);
        toast.success("image uploaded successfully");
      } else if (result.status == "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
      } else if (result.status == "FILE_ERR") {
        toast.error(result.Backend_Error);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // for deleting image
  const deleteImage = async () => {
    try{
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          id: Number(deleteId),
        }),
      };
  
      const response = await fetch(`${base_url}/auth/banner/delete/banner`, requestOptions)
      const result = await response.json();
            if (result.status == 1) {
              handleClose1()
              setTabRefresh(!tabRefresh);
              toast.success("banner deleted successfully");
            } else if (result.status == "TOKEN ERR") {
              localStorage.removeItem("brainbucks_token");
              localStorage.removeItem("username");
              Navigate("/");
            } else if (result.status == "FILE_ERR") {
              toast.error(result.Backend_Error);
            }
    }catch(e){
      console.log(e);
    }
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{ height: "100vh", overflowY: "hidden" }}
      >
        <div className="row h-100">
          <div className="col-2 h-100 p-0">
            <Sidebar />
          </div>

          {/* Main Content */}
          <div className="col-10 h-100 p-0">
            <div style={{ height: "10%", background: "#2C62EE" }}>
              <Header />
            </div>

            {/* button */}
            <div
              style={{
                float: "right",
                margin: "1rem",
                marginTop: "2rem",
              }}
            >
              <button
                className="btn btn-primary "
                onClick={handleShow}
                // style={{ background: "#2C62EE", fontSize: "1rem" }}
              >
                + Add Image
              </button>
            </div>

            <div
              className="container-fluid pb-4 mb-5 pt-2"
              style={{ height: "80%", overflowY: "auto" }}
            >
              <div className="row row-gap-4">
                {array.length <= 0 ? (
                  <>
                    {isLoading == true ? (
                      <>
                        <div className="col-6 h-100 text-center w-100 mx-auto">
                          <InfinitySpin width="300" color="#3D70F5" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mt-5">
                          <h5 className="text-center mt-5">
                            No Banner Found , Please Add Banners !
                          </h5>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  array.map((blob) => (
                    <div className="col-4" key={blob.id}>
                      <div className="card h-100 p-2">
                        <img
                          src={`${blob.url}`}
                          alt={blob.id}
                          className="w-100"
                          style={{
                            objectFit: "cover",
                            height: "200px",
                            objectPosition: "center center",
                          }}
                        />
                        <div className="my-1">
                          <button
                            style={{ float: "right" }}
                            className="btn btn-danger"
                            type="click"
                            onClick={() => {
                              setDeleteId(blob.id)
                              handleShow1()
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* add banner modal started  */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <input
            type="file"
            accept="image/*"
            onChange={(event) => {
              setFile(event.target.files[0]);
            }}
          />
        </Modal.Header>

        <Modal.Footer centered>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="btn btn-primary"
            onClick={() => {
              handleFileUpload();
              handleClose();
            }}
          >
            Upload Image
          </Button>
        </Modal.Footer>
      </Modal>


      {/* for deleting banners */}
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
            <p style={{color:"red"}}>Are you sure you want to delete this banner ?</p>
        </Modal.Header>

        <Modal.Footer centered>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="btn btn-danger"
            onClick={deleteImage}
          >
            Delete Banner
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add banner modal end  */}
    </>
  );
};

export default BannerMain;
