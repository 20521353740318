import React from 'react'
import Table from 'react-bootstrap/Table';
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const ByBrainBucksTable = ({users}) => {
  const Navigate = useNavigate();
  return (
    <>
    <Table responsive className='border'>


  <thead>
    <tr style={{fontSize:"1.2rem"}}>
      <th>S.No</th>
      <th>Course</th>
      <th>Created BY</th>
      <th>Created On</th>
      <th>Amount</th>
      <th>Action</th>

    </tr>
  </thead>


  <tbody>


   

    {users.map((user , index)=>(
         <tr>
        <td className="align-middle" >{index+1}</td>
        <td className="align-middle" >{user.prereccou_name}</td>
        <td className="align-middle" >{user.name}</td>
        <td className="align-middle" >{moment(user.createdOn).format("DD-MM-YYYY")}</td>
        <td className="align-middle" > ₹ {user.amount}</td>
        <td className="align-middle" >
            <button className='btn btn-primary py-1'
                           onClick={()=>{
                            Navigate(`/Users/ViewUser/${user.id}`)
                          }}
            >View Profile</button>
            
            
            </td>

        </tr>
    ))}

   

  </tbody>



</Table>
</>
)
  
}

export default ByBrainBucksTable