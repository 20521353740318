import React, { useState, useEffect, useContext } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { useParams, useNavigate } from "react-router-dom";
import { base_url } from "../env.js";
import toast, { Toaster } from "react-hot-toast";
import { Context } from "../../index.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const TagDetails = () => {
  const { freeQuestionsRefresh } = useContext(Context);
  let { _id } = useParams();
  const [tagData, setTagData] = useState([]);
  const Navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  //  comp states
  const [file, setFile] = useState(null);

  // api for getting question data
  const getQuestionsData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          _id: _id,
        }),
      };

      const response = await fetch(
        `${base_url}/quiz/prerec_topic/view/pre/rec/tag/byid`,
        requestOptions
      );
      const result = await response.json();
      if (result.status == 1) {
        setTagData(result.detail[0]);
      }
      if (result.status === "VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == "FILE_VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
        toast("Logged in other device! Please login again");
      }
    } catch (e) {
      console.log(e);
    }
  };



  // for uploading excel file
  const handleFileUpload = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      const formData = new FormData();
      formData.append("prerec_tagid",_id);
      formData.append("excel", file);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };

      const response = await fetch(
        `${base_url}/quiz/prerec_question/upload/excel/of/prerectagquestions`,
        requestOptions
      );
      const result = await response.json();
      if (result.status == 1) {
        handleClose()
        setFile(null);
        toast.success(result.msg);

      } else if (result.status == "TOKEN_ERR") {

        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
      } else if (result.status == "FILE_ERR") {
        toast.error(result.Backend_Error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // for deleting a particular question
  const deleteQuestion = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          // id
        }),
      };

      const response = await fetch(
        `${base_url}/quiz/prerec_topic/get/pre/rec/tag`,
        requestOptions
      );
      const result = await response.json();
      if(result.status==1){
        toast.success("Question deleted successfully");
      }
      if (result.status === "VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == "FILE_VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == "TOKEN_ERR") {
        localStorage.removeItem("brainbucks_token");
        localStorage.removeItem("username");
        Navigate("/");
        toast("Logged in other device! Please login again");
      }
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    getQuestionsData();
  }, [freeQuestionsRefresh]);

  return (
    <>
    <Toaster/>
      <div

        className="container-fluid w-100 p-0"
        style={{ height: "100vh", overflowY: "hidden", background: "#FFFFFF" }}
      >
        <div style={{ height: "27vh", background: "#FFFFFF" }}>
          <div className=" p-4">
            <div className="btn btn-dark px-4 py-0">
              {
                <FaLongArrowAltLeft
                  fontSize={"30px"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    Navigate(-1);
                  }}
                />
              }
            </div>

            <div className="d-flex flex-row justify-content-between pt-3">
              <div>
                <h5>Tag Details</h5>
              </div>

              <div className="d-flex flex-row gap-3">
                <button
                  onClick={handleShow}
                  type="button"
                  style={{ border: "1px solid green", color: "green" }}
                  className="btn btn-Light btn-sm"
                >
                  {<CiEdit fontSize={"20px"} />} Add Excel
                </button>

                <button
                  onClick={() => {
                    Navigate(`AddQuestion`);
                  }}
                  type="button"
                  className="btn btn-primary btn-sm"
                >
                  +Add Questions
                </button>
              </div>
            </div>

            <div style={{ fontSize: "small" }}>
              <div>
                Name of Tag : {tagData.topic_name}{" "}
                <span style={{ color: "red" }}>{}</span>
              </div>
              <div>
                {" "}
                <span>Total Number of Questions : </span>
                <span style={{ color: "red" }}>
                  {tagData.totalQuestions}
                </span>{" "}
              </div>
              <div>Course : {tagData.prerec_couname} </div>
            </div>
          </div>
        </div>

        <div className="mx-4" style={{ height: "73vh", width: "70vw" }}>
          {/* heading */}
          <div
            className="justify-content-between  d-flex px-2"
            style={{ height: "4vh", color: "blue" }}
          >
            <div>Questions</div>
            <div>Actions</div>
          </div>

          <div
            style={{ height: "68vh", overflowY: "auto" }}
            className="example border shadow rounded"
          >
            {/* questions div */}

            {tagData.questions?.length == 0 ? (
              <div className="align-middle text-center">
                <h1>Please Add Questions</h1>{" "}
              </div>
            ) : (
              tagData.questions?.map((data, index) => (
                <div
                  style={{
                    height: "fit-content",
                    display: "flex",
                    borderBottom: "1px solid gray",
                    // borderBottom: "1px solid gray",
                  }}
                >
                  <div style={{ width: "90%" }}>
                    <div
                      style={{
                        margin: "2%",
                        width: "full",
                        height: "fit-content",
                      }}
                    >
                      <div>
                        {index + 1}. {data.question}
                      </div>

                      {/* use ternary operator for color */}
                      <div
                      // style={{ marginLeft: "2%" }}
                      >
                        <div
                          className="p-1 m-1 rounded"
                          style={{
                            border:
                              data.ans == 1
                                ? "2px solid green"
                                : "1px solid gray",
                            fontSize: "13px",
                            color: data.ans == 1 ? "green" : "gray",
                            width: "60%",
                          }}
                        >
                          A. {data.option1}
                        </div>
                        <div
                          className="p-1 m-1 rounded"
                          style={{
                            border:
                              data.ans == 2
                                ? "2px solid green"
                                : "1px solid gray",
                            fontSize: "13px",
                            color: data.ans == 2 ? "green" : "gray",
                            width: "60%",
                          }}
                        >
                          B. {data.option2}
                        </div>
                        <div
                          className="p-1 m-1 rounded"
                          style={{
                            border:
                              data.ans == 3
                                ? "2px solid green"
                                : "1px solid gray",
                            fontSize: "13px",
                            color: data.ans == 3 ? "green" : "gray",
                            width: "60%",
                          }}
                        >
                          C. {data.option3}
                        </div>
                        <div
                          className="p-1 m-1 rounded"
                          style={{
                            border:
                              data.ans == 4
                                ? "2px solid green"
                                : "1px solid gray",
                            fontSize: "13px",
                            color: data.ans == 4 ? "green" : "gray",
                            width: "60%",
                          }}
                        >
                          D. {data.option4}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex gap-4"
                    style={{
                      width: "10%",
                      justifyContent: "end",
                      paddingRight: "1rem",
                      borderRight: "1px solid gray",
                      borderLeft: "1px solid gray",
                    }}
                  >
                    {/* <div>
                {<MdEdit fontSize={"20px"} style={{ cursor: "pointer" }} />}
              </div> */}
                    <div>
                      {
                        <MdDelete
                          fontSize={"20px"}
                          style={{ cursor: "pointer" }}
                        />
                      }
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/* add banner modal started  */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(event) => {
              setFile(event.target.files[0]);
            }}
          />
        </Modal.Header>

        <Modal.Footer centered>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="btn btn-primary"
            onClick={handleFileUpload}
          >
            Upload Excel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add banner modal end  */}
    </>
  );
};

export default TagDetails;
