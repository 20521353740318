import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Slider from "@mui/material/Slider";
import Modal from "react-bootstrap/Modal";
import { token, base_url } from "../env";
// import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Quizzes = (props) => {
  const colors = ["#2C62EE", "#C922E4", "#CC1313", "#FF8E09"];
  const Navigate = useNavigate();
  const [currentQuiz, setCurrentQuiz] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow(true);
  const handleClose1 = () => setShow1(false);

  const handleShow = (quizId) => {
    setCurrentQuiz(quizId);
    setShow(true);
  };
  const [quizzesData, setQuizzesData] = useState([]);
  const [previewData, setPreviewData] = useState();

  const getQuizzes = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("brainbucks_token")}`);

      var raw = JSON.stringify({
        prerec_couid: props.currentCourseId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

     const response = await fetch(
        `${base_url}/quiz/prerec_quiz/get/quiz/in/prereccou`,
        requestOptions
      )
      const result = await response.json();
          if (result.status == 1) {
            setQuizzesData(result.quizes);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
          }
    } catch (error) {
      console.log(error);
    }
  };

  const particularQuiz = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("brainbucks_token")}`);

      var raw = JSON.stringify({
        quiz_id: currentQuiz,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

    const response = await fetch(
        `${base_url}/quiz/prerec_quiz/view/detail/particular/prerecquiz`,
        requestOptions
      )
      const result = await response.json();
          if (result.status == 1) {
            setPreviewData(result.quiz_data);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
          }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getQuizzes();
    if (currentQuiz !== null) {
      particularQuiz();
    }
  }, [currentQuiz]);

  return (
    <>
      {/* <div className="container"> */}
      <div className="css-grid">
        {quizzesData.map((data, value) => (
          <div
            className="h-100 py-3 px-2 "
            style={{ border: "1px solid #E6E6E6", borderRadius: "5px" }}
          >
            <h6 style={{ fontWeight: 500, fontSize: "0.9rem" }}>
              {data.quiz_name}
            </h6>
            <p
              style={{
                fontSize: "0.7rem",
                color: "#9A9A9A",
                fontWeight: 300,
              }}
            >
              Courses : {data.prerec_couname}
            </p>
            <p
              style={{
                fontSize: "0.7rem",
                color: "#9A9A9A",
                fontWeight: 300,
              }}
            >
              Video : {data.prerec_videoname}
            </p>
            <p
              className="mt-1"
              style={{
                fontSize: "0.7rem",
                color: "#3C3C3C",
                fontWeight: 300,
              }}
            >
              Questions : {data.number_of_question}
            </p>
            <div className="d-flex align-items-center justify-content-between mb-0 pb-0">
              <p
                style={{
                  fontSize: "0.7rem",
                  color: "#3C3C3C",
                  fontWeight: 500,
                }}
              >
                Passing Percentage
              </p>
              <p
                style={{
                  fontSize: "0.7rem",
                  color: "#3D70F5",
                  fontWeight: 500,
                }}
              >
                {data.pass_pers}%
              </p>
            </div>
            <ProgressBar
              className=""
              completed={data.pass_pers}
              bgColor="#3D70F5"
              height="5px"
              labelAlignment="center"
              isLabelVisible={false}
              labelColor="#e8090"
              labelSize="30px"
              animateOnRender
              maxCompleted={100}
            />

            <div className="my-3 mb-0">
              <button
                onClick={() => {
                  handleShow(data._id);
                }}
                className="btn py-2 w-100 text-white"
                style={{ background: "#2C62EE" }}
              >
                View Details
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* </div> */}

      {/* view quiz details offcanvas started  */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ width: "50%" }}
      >
        <Offcanvas.Header className="d-flex align-items-center justify-content-between">
          <svg
            style={{ cursor: "pointer" }}
            onClick={handleClose}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.8518 4.18608C18.9199 4.11789 18.974 4.03695 19.0109 3.94787C19.0478 3.85878 19.0668 3.7633 19.0668 3.66688C19.0668 3.57045 19.0478 3.47497 19.0109 3.38589C18.974 3.2968 18.9199 3.21586 18.8518 3.14768C18.7836 3.07949 18.7026 3.02541 18.6136 2.98851C18.5245 2.95161 18.429 2.93262 18.3326 2.93262C18.2361 2.93262 18.1407 2.95161 18.0516 2.98851C17.9625 3.02541 17.8815 3.07949 17.8134 3.14768L10.9992 9.96328L4.1851 3.14768C4.11692 3.07949 4.03597 3.02541 3.94689 2.98851C3.8578 2.95161 3.76232 2.93262 3.6659 2.93262C3.56948 2.93262 3.474 2.95161 3.38491 2.98851C3.29583 3.02541 3.21488 3.07949 3.1467 3.14768C3.07852 3.21586 3.02443 3.2968 2.98753 3.38589C2.95063 3.47497 2.93164 3.57045 2.93164 3.66688C2.93164 3.7633 2.95063 3.85878 2.98753 3.94787C3.02443 4.03695 3.07852 4.11789 3.1467 4.18608L9.9623 11.0002L3.1467 17.8143C3.009 17.952 2.93164 18.1388 2.93164 18.3335C2.93164 18.5283 3.009 18.715 3.1467 18.8527C3.2844 18.9904 3.47116 19.0678 3.6659 19.0678C3.86064 19.0678 4.0474 18.9904 4.1851 18.8527L10.9992 12.0371L17.8134 18.8527C17.9511 18.9904 18.1378 19.0678 18.3326 19.0678C18.5273 19.0678 18.7141 18.9904 18.8518 18.8527C18.9895 18.715 19.0668 18.5283 19.0668 18.3335C19.0668 18.1388 18.9895 17.952 18.8518 17.8143L12.0362 11.0002L18.8518 4.18608Z"
              fill="#6A6A6A"
            />
          </svg>

          <button
            className="btn d-flex align-items-center gap-1"
            onClick={() => {
              handleClose();
              handleShow1();
            }}
            style={{
              border: "1px solid #E72121",
              borderRadius: "5px",
              color: "#E72121",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M3.75 15.75V4.5H3V3H6.75V2.25H11.25V3H15V4.5H14.25V15.75H3.75ZM5.25 14.25H12.75V4.5H5.25V14.25ZM6.75 12.75H8.25V6H6.75V12.75ZM9.75 12.75H11.25V6H9.75V12.75ZM5.25 4.5V14.25V4.5Z"
                fill="#E72121"
              />
            </svg>
            Delete
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-10 h-100 mx-auto">
                <div className="h-100 d-flex h-100 flex-column justify-content-around">
                  <div className="card p-2 px-5">
                    <p className="mb-0">Preview</p>

                    <div
                      className="mt-3 py-3 example"
                      style={{ height: "61vh", overflowY: "auto" }}
                    >
                      {previewData && (
                        <>
                          <p style={{ color: "#434343" }}>
                            Course :{" "}
                            <span style={{ fontWeight: 800 }}>
                              {previewData.prerec_couname}
                            </span>
                          </p>
                          {/* <p style={{ color: "#434343" }}>
                            Subject :{" "}
                            <span style={{ fontWeight: 800 }}>
                              {data.subject}
                            </span>
                          </p> */}
                          <p style={{ color: "#434343" }}>
                            Number of Questions :{" "}
                            <span style={{ fontWeight: 800 }}>
                              {previewData.number_of_question}
                            </span>
                          </p>
                          <div className="mt-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <p className="mb-0" style={{ color: "#434343" }}>
                                Passing Percentage
                              </p>
                              <p className="mb-0" style={{ color: "#3D70F5" }}>
                                {previewData.pass_pers}%
                              </p>
                            </div>
                            <Slider
                              max={100}
                              className="w-100"
                              disabled
                              style={{ color: "#2C62EE" }}
                              defaultValue={previewData.pass_pers}
                            />
                          </div>

                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-1">Tags Composition</p>
                          </div>
                          <div className="px-3" >

                          {Object.entries(previewData.topics_ratio).map(
                            ([topic, percentage]) => (
                              <div key={topic}>
                                <div className="d-flex mt-3 align-items-center justify-content-between">
                                  <p className="mb-0">{topic}</p>
                                  <p className="mb-0">{percentage}%</p>
                                </div>
                                <Slider
                                  max={100}
                                  className="w-100"
                                  disabled
                                  style={{ color: colors[Math.floor(Math.random() * colors.length)] }}

                                  defaultValue={percentage}
                                />
                              </div>
                            )
                          )}
                          </div>

                          <div>
                            <p className="mb-0" style={{ color: "#434343" }}>
                              Rules
                            </p>
                            <p style={{ fontSize: "0.7rem" }}>
                              {previewData.quiz_rules}
                            </p>
                          </div>

                          <div>
                            <p
                              className="ps-2 p-1"
                              style={{
                                background: "#F5F5F5",
                                color: "#767676",
                                fontSize: "0.9rem",
                                borderRadius: "3px",
                              }}
                            >
                              Times Per Questions :{" "}
                              {previewData.time_per_question}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* view quiz details offcanvas end  */}

      {/* delete button modal started  */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Body className="text-center">
          Quiz “Networking for Beginners” <br /> will be deleted
        </Modal.Body>
        <Modal.Footer className="border-0 pb-3 d-flex align-items-center justify-content-between">
          <button
            variant="secondary"
            className="flex-grow-1 py-2 border-0 btn text-white"
            style={{ background: "#F93333" }}
            onClick={handleClose1}
          >
            Yes, Delete it
          </button>
          <button
            variant="primary"
            className="flex-grow-1 py-2 border-0 btn text-white"
            style={{ background: "#3D70F5" }}
            onClick={handleClose1}
          >
            Don't Delete
          </button>
        </Modal.Footer>
      </Modal>
      {/* delete button modal end  */}
    </>
  );
};

export default Quizzes;
