import React, { useState } from "react";
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import {auth_url, username} from "./env"
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Header = () => {
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

 // api to logout
 const logOutApi = async () => {
  try {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("brainbucks_token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${auth_url}/auth/client/logout`,
      requestOptions
    );
    const result = await response.json();
    if (result.status == 1) {
      localStorage.removeItem("brainbucks_token");
      localStorage.removeItem("username");
      Navigate("/");
    } else if (result.status === "VAL_ERR") {
      toast(result.Backend_Error);
    }
  } catch (e) {
    console.log(e);
  }
};

  return (
    <>
      <div className="d-flex justify-content-between align-items-center h-100 px-3">
        <div>
            <h1 className="mb-0" style={{fontSize:"1.2rem",fontWeight:600,color:"#fff"}} >Hii, {username}</h1>
        </div>
        <div className="d-flex gap-3 align-items-center" >
            {/* <div style={{background:"#3d70f5",borderRadius:"50px",cursor:"pointer"}} className="p-2" >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 26 27" fill="none">
  <path d="M23.4 19.2857H26V21.8571H0V19.2857H2.6V10.2857C2.6 7.55777 3.69571 4.94156 5.64609 3.01262C7.59647 1.08367 10.2417 0 13 0C15.7583 0 18.4035 1.08367 20.3539 3.01262C22.3043 4.94156 23.4 7.55777 23.4 10.2857V19.2857ZM9.1 24.4286H16.9V27H9.1V24.4286Z" fill="#FFF500"/>
</svg>

            </div> */}

            <div style={{background:"#3d70f5",borderRadius:"50px",cursor:"pointer", color: "#fff"}} className="p-2" 
            
             onClick={handleShow}
            >
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
            >
              <path d="M0 8L4.64286 12V9H13V7H4.64286V4L0 8Z" fill="#F93333" />
              <path
                d="M9.79863 3.05398e-05C8.72121 -0.00288353 7.6539 0.202773 6.65854 0.605085C5.66318 1.0074 4.75955 1.59837 4 2.34377L5.28838 3.60051C6.49294 2.42553 8.09476 1.77761 9.79863 1.77761C11.5025 1.77761 13.1043 2.42553 14.3089 3.60051C15.5134 4.77549 16.1777 6.33798 16.1777 8.00002C16.1777 9.66205 15.5134 11.2245 14.3089 12.3995C13.1043 13.5745 11.5025 14.2224 9.79863 14.2224C8.09476 14.2224 6.49294 13.5745 5.28838 12.3995L4 13.6563C5.54806 15.1672 7.60729 16 9.79863 16C11.99 16 14.0492 15.1672 15.5973 13.6563C17.1462 12.1462 18 10.1376 18 8.00002C18 5.86248 17.1462 3.85382 15.5973 2.34377C14.8377 1.59837 13.9341 1.0074 12.9387 0.605085C11.9434 0.202773 10.8761 -0.00288353 9.79863 3.05398e-05Z"
                fill="#F93333"
              />
            </svg>
            <span className="ps-2">Log Out</span>

            </div>

        </div>

       {/* model for log out  */}

       <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Log Out</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure, You Want to Log Out
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary"  onClick={logOutApi}>Log Out</Button>
        </Modal.Footer>
      </Modal>


      </div>
    </>
  );
};

export default Header;
